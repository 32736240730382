import React from 'react';
import Spinner from 'components/Spinner';
import universal from 'react-universal-component';

import styles from './loader.scss';

/* eslint-disable no-control-regex */
const AppRoutes = universal(
  import(/* webpackChunkName: "app" */ '.'),
  {
    loading: () => (
      <>
        <div className={styles.loading}>
          <Spinner size={100} />
        </div>
      </>
    ),
    error: (data) => {
      console.error(data.error);
      return (
        <div className={styles.loading}>
          <div className={styles.error}>
            Failed to load page!
            <pre>
              {data.error.message.replace(
                /[\u001b\u009b][[()#;?]*(?:[0-9]{1,4}(?:;[0-9]{0,4})*)?[0-9A-ORZcf-nqry=><]/g,
                '',
              )}
            </pre>
          </div>
        </div>
      );
    },
  },
);

export default AppRoutes;
