import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import dhid from '../../dhid';
import { AUTH } from './auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';

const CONNECTIONS = defineAction(
  'CONNECTIONS',
  [LOADING, SUCCESS, ERROR, 'DISCONNECTED'],
  'connections',
);

const defaultState = Map({
  data: undefined,
  loading: false,
  error: false,
  attempt: false,
});

const loaded = (data) => (
  { type: CONNECTIONS.SUCCESS, data }
);

const loading = () => (
  { type: CONNECTIONS.LOADING }
);

const loadingError = (error) => (
  { type: CONNECTIONS.ERROR, error }
);

const disconnected = (id) => (
  { type: CONNECTIONS.DISCONNECTED, id }
);

export function getConnections() {
  return (dispatch) => {
    dispatch(loading());
    dhid.request('1/identity/me/connections')
      .then(({ data }) => {
        dispatch(loaded(data));
      })
      .catch((error) => (
        dispatch(loadingError(error))
      ));
  };
}

export function disconnect(id) {
  return (dispatch) => {
    dispatch(loading());
    dhid.request(`1/identity/me/connections/${id}`, {
      method: 'delete',
    })
      .then(({ data }) => {
        dispatch(disconnected(data.id));
      })
      .catch((error) => (
        dispatch(loadingError(error))
      ));
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CONNECTIONS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', false)
        // This part camelcases the incoming data, but excludes any pattern matching a UUID
        .set('data', action.data);
    }
    case CONNECTIONS.LOADING: {
      return state
        .set('error', false) // Clear the previous error
        .set('loading', true)
        .set('attempt', true);
    }
    case CONNECTIONS.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case CONNECTIONS.DISCONNECTED: {
      return state
        .set('loading', false)
        .set('data', state.get('data').filter((o) => o.id !== action.id));
    }
    case AUTH.LOGOUT:
      return state
        .set('loading', false)
        .set('error', false)
        .set('data', undefined)
        .set('attempt', false);
    default: {
      return state;
    }
  }
}
