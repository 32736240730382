import * as Sentry from '@sentry/browser';
/* eslint-disable import/no-extraneous-dependencies */
import axios from 'axios';

/* global cordova device ImgCache API_URL CDN_BASEURL ID_URL */

export const isMobile = () => (
  typeof cordova !== 'undefined' || localStorage.getItem('forceMobileMode') || false
);

export const getDevice = () => (
  typeof device !== 'undefined' && device.platform
);

export const copyToClipboard = (text, onSuccess, onError) => (
  cordova.plugins.clipboard.copy(text, onSuccess, onError)
);

export const getDeviceToken = () => (
  window && window.FirebasePlugin && new Promise((resolve) => (
    window.FirebasePlugin.getToken((token) => (
      resolve(token)
    ))
  ))
);

export const notificationPermission = () => (
  new Promise((resolve) => window.FirebasePlugin.hasPermission((data) => resolve(data)))
);

export const hideKeyboardAccessoryBar = (bool) => {
  if (cordova && cordova.plugins.Keyboard) {
    cordova.plugins.Keyboard.hideKeyboardAccessoryBar(bool);
  }
};

export const closeKeyboard = () => {
  if (cordova && cordova.plugins.Keyboard) {
    cordova.plugins.Keyboard.close();
  }
};

export const openLogin = () => (
  // Redirect to login with return url "dreamhackapp", handled specifically by
  // withUserRedirect
  window.open(`${LOGIN_URL}/ZHJlYW1oYWNrYXBw`) // eslint-disable-line no-undef
);

let defaultBrightness;

export const brightenScreen = () => {
  if (cordova && cordova.plugins.brightness) {
    const { brightness } = cordova.plugins;
    brightness.getBrightness((b) => {
      defaultBrightness = b;
      brightness.setBrightness(1);
    });
  }
};

export const resetScreen = () => {
  if (cordova && cordova.plugins.brightness && defaultBrightness) {
    cordova.plugins.brightness.setBrightness(defaultBrightness);
  }
};

export const NativeImgCache = typeof (ImgCache) !== 'undefined' && ImgCache;

/* Creates a ghetto instance of DHID that is more barebone than the one created
 * by menu.js. This is probably not the most optimal way. Optimally, dhid
 * could be hosted inside dreamhack.com.
 */
export const createDhid = (user, auth) => {
  const baseURL = API_URL;
  const cdnURL = CDN_BASEURL;
  const idURL = ID_URL;

  let interceptor = false;

  const axiosInstance = axios.create({
    baseURL,
  });

  const primusInstanceCallbacks = [];
  let primusInstance = null;

  const dhid = {
    ...axiosInstance,
    getUserID: () => (user ? user.id : null),
    setPrimusInstance: (primus) => {
      primusInstance = primus;

      primusInstanceCallbacks.forEach((callback) => callback(primusInstance));
    },
    onPrimusInstance: (callback) => {
      primusInstanceCallbacks.push(callback);
      if (primusInstance) {
        callback(primusInstance);
      }
    },
    getUser: () => user || null,
    getToken: () => dhid.defaults.headers.common.Authorization,
    getBaseUrl: () => idURL,
    getApiUrl: () => baseURL,
    getFilebankUrl: () => cdnURL,
    getImageUrl: (id, size = '200x200', type = 'jpg') => {
      const extension = size === 'original' ? '' : `.${type}`;
      return `${cdnURL}/images/${id}/${size}${extension}`;
    },
    getVideoUrl: (id, size = 'original', type = 'mp4') => (
      `${cdnURL}/videos/${id}/${size}.${type}`
    ),
    setToken: (token) => {
      if (!token) {
        dhid.interceptors.response.eject(interceptor);
        clearInterval();
      }
      if (!dhid.defaults) {
        dhid.defaults = {};
      }
      if (!dhid.defaults.headers) {
        dhid.defaults.headers = {};
      }
      if (!dhid.defaults.headers.common) {
        dhid.defaults.headers.common = {};
      }
      const handler = (response) => {
        if (response.headers && response.headers.authorization) {
          dhid.setToken(response.headers.authorization);
        }
        // Errors are wonky with cordova file:// etc.
        if (response.isAxiosError && isMobile()) {
          throw response;
        }
        return response;
      };
      if (!interceptor) {
        interceptor = dhid.interceptors.response.use(handler);
      }
      dhid.defaults.headers.common.Authorization = token;
      return dhid;
    },
    setUser: (newUser) => {
      /* eslint-disable no-param-reassign */
      user = newUser;
      Sentry.configureScope((scope) => scope.setUser(user || {}));
    },
    // When the app is woken up, we refresh the user info from the server
    refreshUser: () => dhid.get('1/identity/me').then(({ data }) => dhid.setUser(data)),
    getPrimusInstance: () => primusInstance,
  };

  if (auth) {
    const authWithBearer = `Bearer ${auth}`;
    dhid.setToken(authWithBearer);
  }

  return dhid;
};
