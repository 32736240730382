import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { createOnlyOnceCondition } from '../conditions';
import { postAndDispatch, requestAndDispatch } from '../helpers';

const APPLICATIONS = defineAction(
  'APPLICATIONS',
  [LOADING, SUCCESS, ERROR, 'REMOVED'],
  'applications',
);

const defaultState = Map({
  list: null,
  loading: false,
  error: false,
});

const loaded = (data) => (
  { type: APPLICATIONS.SUCCESS, data }
);

const loading = () => (
  { type: APPLICATIONS.LOADING }
);

const loadingError = (error) => (
  { type: APPLICATIONS.ERROR, error }
);

const removed = (id) => (
  { type: APPLICATIONS.REMOVED, id }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
  onSuccess: ({ data }) => loaded(data),
};

const { condition: onlyOnce, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();

export const getApplications = () => requestAndDispatch({
  url: '1/crew/applications',
  condition: onlyOnce,
}, defaults);

export const getApplicationsForTeam = (teamId) => requestAndDispatch({
  url: '1/crew/applications/team/'.concat(teamId),
  condition: onlyOnce,
}, defaults);

export const getRelatedApplications = (applicationId) => requestAndDispatch({
  url: `1/crew/applications/${applicationId}/related`,
  condition: onlyOnce,
}, defaults);

export const postApplication = (postData) => postAndDispatch({
  url: '1/crew/applications',
  data: postData,
  onSuccess: ({ data }) => loaded({ [data.id]: data }),
}, defaults);

export const updateApplication = (id, postData) => postAndDispatch({
  url: `1/crew/applications/${id}`,
  data: postData,
  onSuccess: ({ data }) => loaded({ [data.id]: data }),
}, defaults);

export const removeApplication = (id) => requestAndDispatch({
  url: `1/crew/applications/${id}`,
  method: 'delete',
  onSuccess: ({ data }) => removed(data.id),
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case APPLICATIONS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', false)
        // This part camelcases the incoming data, but excludes any pattern matching a UUID
        .set('list', (state.get('list') || Map()).mergeDeep(action.data));
    }
    case APPLICATIONS.LOADING: {
      return state
        .set('error', false) // Clear the previous error
        .set('loading', true);
    }
    case APPLICATIONS.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case APPLICATIONS.REMOVED: {
      return state
        .set('loading', false)
        .set('data', state.get('data').filter((o) => o.id !== action.id));
    }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
