import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './button.scss';

const propTypes = {
  outline: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  muted: PropTypes.bool,
  type: PropTypes.string,
  colors: PropTypes.shape({
    text: PropTypes.string,
    border: PropTypes.string,
    background: PropTypes.string,
  }),
};

const Button = (props) => {
  const colors = {
    ...(props.colors && props.colors.text) ? { color: `${props.colors.text}` } : {},
    ...(props.colors && props.colors.border) ? { borderColor: `${props.colors.border}` } : {},
    ...(props.colors && props.colors.background) ? { backgroundColor: `${props.colors.background}` } : {},
  };

  // The rule below does not agree that the type is set. I think it's a bug in
  // eslint, so if you feel like testing after eslint has been upgraded or smth
  // just remove and try it out. I'm fine with how this works for now.
  /* eslint-disable react/button-has-type */
  return (
    <button
      onClick={props.onClick}
      disabled={props.disabled}
      className={classNames(
        styles.button,
        { [`${styles.outline}`]: props.outline },
        { [`${styles.muted}`]: props.muted },
      )}
      style={colors}
      type={props.type || 'button'}
    >
      {props.children}
    </button>
  );
};

Button.propTypes = propTypes;

export { default as BarButton } from './BarButton';
export default Button;
