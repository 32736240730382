import dhid from '../dhid';

const runConditions = (opts) => {
  if (typeof opts === 'function') {
    const res = opts();
    // Here is the important row, if !res skip is set to true and the whole request will be ignored
    return { opts: res, skip: !res };
  }

  if (opts !== undefined && opts !== null && typeof opts.map === 'function') { // loop thru all items in a iterable
    let skip = false;
    const res = opts.map((itemOpts) => {
      const { opts: resThis, skip: skipThis } = runConditions(itemOpts);
      skip = skipThis || skip;
      return resThis;
    });
    return { opts: res, skip };
  }

  if (opts !== undefined && opts !== null && typeof opts === 'object') { // loop thru all items in an object
    let skip = false;
    const res = {};
    Object.keys(opts).forEach((key) => {
      const { opts: resThis, skip: skipThis } = runConditions(opts[key]);
      skip = skipThis || skip;
      res[key] = resThis;
    });
    return { opts: res, skip };
  }

  return { opts, skip: false }; // everything else, just return
};

export const requestAndDispatch = (overrides, defaults) => {
  const opts = {
    ...defaults,
    ...overrides,
  };

  const {
    url,
    onLoading,
    onSuccess,
    onFailure,
    condition,
    ...requestOpts
  } = opts;

  // run option conditions and check if we should skip the request
  const { opts: processedOpts, skip } = runConditions(requestOpts);
  if (skip) {
    return () => Promise.all([]);
  }

  if (condition && condition({ ...requestOpts, url })() === null) {
    return () => Promise.all([]);
  }

  return (dispatch) => {
    if (onLoading) {
      dispatch(onLoading());
    }

    return dhid.request(url, processedOpts)
      .then((resp) => {
        if (onSuccess) {
          dispatch(onSuccess(resp));
        }
        return resp;
      })
      .catch((error) => {
        if (onFailure) {
          dispatch(onFailure(error));
        }
        throw error;
      });
  };
};

export const postAndDispatch = (overrides, defaults) => {
  const postOpts = {
    ...overrides,
    method: 'post',
  };
  return requestAndDispatch(postOpts, defaults);
};

export const putAndDispatch = (overrides, defaults) => {
  const putOpts = {
    ...overrides,
    method: 'put',
  };
  return requestAndDispatch(putOpts, defaults);
};

export const deleteAndDispatch = (overrides, defaults) => {
  const putOpts = {
    ...overrides,
    method: 'delete',
  };
  return requestAndDispatch(putOpts, defaults);
};

export const getCsrfToken = () => (
  dhid.request(`${dhid.getBaseUrl().replace(/\/+$/, '')}/token`, {
    method: 'post',
    mode: 'cors',
    withCredentials: true,
  })
    .then(({ data }) => data.csrfToken)
);
