import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import dhid from '../../dhid';
import {
  ERROR,
  LOADING,
  SAVING,
  SUCCESS,
} from '../stateConstants';

const USER_DATA = defineAction(
  'USER_DATA',
  [LOADING, SUCCESS, 'SAVE_SUCCESS', ERROR, SAVING],
  'userData',
);

const defaultState = Map({
  data: undefined,
  loading: false,
  saving: false,
  error: undefined,
});

const loaded = (data) => (
  { type: USER_DATA.SUCCESS, data }
);

const saved = () => (
  { type: 'USER_DATA_SAVE_SUCCESS' }
);

const loading = () => (
  { type: USER_DATA.LOADING }
);

const saving = () => (
  { type: USER_DATA.SAVING }
);

const loadingError = (error) => (
  { type: USER_DATA.ERROR, error }
);

export function getUserData() {
  return (dispatch) => {
    dispatch(loading());
    return dhid.request('1/identity/me/data')
      .then(({ data }) => dispatch(loaded(data)))
      .catch((error) => dispatch(loadingError(error)));
  };
}

export function setUserData(data) {
  return (dispatch) => {
    dispatch(saving());
    return dhid.request('1/identity/me/data', {
      method: 'post',
      data,
    })
      .then(() => dispatch(saved()))
      .catch((error) => dispatch(loadingError(error)));
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USER_DATA.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('data', action.data);
    }
    case 'USER_DATA_SAVE_SUCCESS':
      return state
        .set('saving', false)
        .set('error', undefined);
    case USER_DATA.LOADING: {
      return state
        .set('error', undefined)
        .set('loading', true);
    }
    case USER_DATA.SAVING: {
      return state
        .set('error', undefined)
        .set('saving', true);
    }
    case USER_DATA.ERROR: {
      return state
        .set('loading', false)
        .set('saving', false)
        .set('error', serializeError(action.error));
    }
    default: {
      return state;
    }
  }
}
