import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './subheader.scss';

const propTypes = {
  text: PropTypes.string,
  children: PropTypes.node,
  borderBottom: PropTypes.bool,
  big: PropTypes.bool,
  buttons: PropTypes.node,
};

const Subheader = ({
  text,
  children,
  borderBottom,
  big,
  buttons,
}) => (
  <div className={classNames(
    styles.subheader,
    {
      [styles.borderBottom]: borderBottom,
      [styles.big]: big,
    },
  )}
  >
    {text}
    {children}
    {buttons && (
      <div className={styles.buttons}>
        {buttons}
      </div>
    )}
  </div>
);

Subheader.propTypes = propTypes;

export default Subheader;
