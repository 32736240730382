import PropTypes from 'prop-types';
import React from 'react';
import classnames from 'classnames';

import RavenErrorBoundary from '../../../../components/RavenErrorBoundary';
import styles from './content.scss';

const propTypes = {
  children: PropTypes.node,
  id: PropTypes.string,
  passRef: PropTypes.func,
  transparent: PropTypes.bool,
};

const Content = ({
  children,
  id,
  passRef,
  transparent,
}) => (
  <div
    id={id}
    className={classnames(
      styles.content,
      transparent && styles.transparent,
    )}
    ref={passRef ? (c) => passRef(c) : undefined}
  >
    <RavenErrorBoundary>
      {children}
    </RavenErrorBoundary>
  </div>
);

Content.propTypes = propTypes;

export default Content;
