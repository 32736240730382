import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { getDevice, isMobile } from '../../../../mobile/helpers';
import { navigatePush } from '../../../../helpers';
import styles from './header.scss';
import withUser from '../../../../components/withUser';

const propTypes = {
  children: PropTypes.node,
  tabs: PropTypes.arrayOf(PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    active: PropTypes.bool,
  })),
  right: PropTypes.node,
  left: PropTypes.node,
  onMenuOpen: PropTypes.func,
  onBack: PropTypes.func,
  showUser: PropTypes.bool,
  history: PropTypes.shape({
    stackPush: PropTypes.func.isRequired,
  }),
  showOffers: PropTypes.bool,
  offers: PropTypes.shape(),
  seenOffers: PropTypes.shape(),
  loading: PropTypes.bool,
  transparent: PropTypes.bool,
};

const debounce = (callback, wait, immediate) => {
  let timer;
  let resp;
  return [(...args) => {
    const later = () => {
      timer = null;
      if (!immediate) {
        resp = callback.apply(this, args);
      }
    };

    if (immediate && !timer) {
      resp = callback.apply(this, args);
    }

    clearTimeout(timer);
    timer = setTimeout(later, wait);

    return resp;
  }, () => clearTimeout(timer)];
};

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    // Add a 500ms debouncer to stop the loading animation from flickering between requests
    const [setLoading, cancelLoading] = debounce(
      (loading) => this.setState({ loading }),
      500,
    );

    this.setLoading = setLoading;
    this.cancelLoading = cancelLoading;
  }

  componentWillUnmount() {
    this.cancelLoading();
  }

  getSnapshotBeforeUpdate() {
    const { loading } = this.props;

    if (loading) {
      // Set loading status right away when loading starts
      this.setState({ loading });
    } else {
      // Else use the debouncer
      this.setLoading(loading);
    }

    return null;
  }

  render = () => {
    const {
      children,
      history,
      left,
      offers,
      onBack,
      onMenuOpen,
      right,
      seenOffers,
      showOffers,
      showUser,
      tabs,
      transparent,
    } = this.props;

    const offersCount = showOffers
      && offers
      && offers.filter(
        (offer) => !seenOffers.contains(offer.get('id')) && !offer.get('my_codes').size && offer.get('eligible'),
      ).size;

    return (
      <div className={classNames(
        styles.header,
        {
          [styles.headerIos]: isMobile() && getDevice() === 'iOS',
        },
        transparent && styles.transparent,
      )}
      >
        <div className={classNames(
          styles.container,
        )}
        >
          <div className={styles.title}>
            {children}
            <CSSTransition
              in={this.state.loading}
              classNames={{
                enter: styles.loadingEnter,
                enterActive: styles.loadingEnterActive,
                exit: styles.loadingExit,
                exitActive: styles.loadingExitActive,
              }}
              timeout={300}
              unmountOnExit
            >
              <div className={styles.loading}><span className="far fa-spinner-third fa-spin" /></div>
            </CSSTransition>
          </div>
          <div className={styles.buttons}>
            <div className={styles.left}>
              { onBack
                && (
                <div
                  className={styles.back}
                  role="button"
                  tabIndex="0"
                  onClick={onBack}
                >
                  <i className="far fa-arrow-left" />
                </div>
                )}
              { !onBack && onMenuOpen
                && (
                <div
                  className={styles.menu}
                  role="button"
                  tabIndex="0"
                  onClick={onMenuOpen}
                >
                  <i className="far fa-bars" />
                </div>
                )}
              {left}
            </div>

            <div className={styles.center} />

            <div className={styles.right}>
              {right}
              { showUser && (
                <>
                  <div
                    className={styles.user}
                    role="button"
                    tabIndex={0}
                    onClick={() => navigatePush(history, 'me')}
                  >
                    <i className="far fa-user">
                      {offersCount > 0 && (
                        <span className={styles.badge}>
                          {offersCount}
                        </span>
                      )}
                    </i>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        { tabs
          && (
          <div className={styles.tabs}>
            { tabs.map((tab) => (
              <div
                onClick={() => tab.onClick()}
                role="button"
                tabIndex="0"
                key={tab.title}
                className={classNames(
                  styles.tab,
                  {
                    [`${styles.active}`]: tab.active,
                  },
                )}
              >
                {tab.title}
              </div>
            ))}
          </div>
          )}
      </div>
    );
  }
}

Header.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    showOffers: state.getIn(['app', 'settings', 'showOffers']),
    activeEvent: state.getIn(['event', 'activeEvent']),
    quizOpen: state.getIn(['app', 'settings', 'quizOpen']),
    seenOffers: state.getIn(['app', 'seenOffers']),
    offers: state.getIn(['offers', 'data']),
  };
}

export default withUser(connect(mapStateToProps)(Header));
