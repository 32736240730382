// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-RavenErrorBoundary-raven-error-boundary__darkContainer--3w3FF,.src-components-RavenErrorBoundary-raven-error-boundary__lightContainer--1lyx8{display:flex;height:100%;align-items:center;justify-content:center}.src-components-RavenErrorBoundary-raven-error-boundary__error--3IBfS{color:#eee;padding:20px;font-weight:400;text-align:center;max-width:480px}.src-components-RavenErrorBoundary-raven-error-boundary__title--3_tsd{font-size:18px;font-weight:500;margin-bottom:8px}.src-components-RavenErrorBoundary-raven-error-boundary__message--3hupt{font-size:14px;margin-bottom:12px}.src-components-RavenErrorBoundary-raven-error-boundary__dev--k8tlf{font-size:10px;color:#999}.src-components-RavenErrorBoundary-raven-error-boundary__lightContainer--1lyx8 .src-components-RavenErrorBoundary-raven-error-boundary__error--3IBfS{color:inherit}\n", ""]);
// Exports
exports.locals = {
	"darkContainer": "src-components-RavenErrorBoundary-raven-error-boundary__darkContainer--3w3FF",
	"lightContainer": "src-components-RavenErrorBoundary-raven-error-boundary__lightContainer--1lyx8",
	"error": "src-components-RavenErrorBoundary-raven-error-boundary__error--3IBfS",
	"title": "src-components-RavenErrorBoundary-raven-error-boundary__title--3_tsd",
	"message": "src-components-RavenErrorBoundary-raven-error-boundary__message--3hupt",
	"dev": "src-components-RavenErrorBoundary-raven-error-boundary__dev--k8tlf"
};
module.exports = exports;
