import { defineAction } from 'redux-define';
import { Map } from 'immutable';
import { LOADING, SUCCESS, ERROR } from './stateConstants';
import { requestAndDispatch } from './helpers';

const LEADERBOARDS = defineAction(
  'LEADERBOARDS',
  [LOADING, SUCCESS, ERROR, 'SUCCESS_SINGLE'],
  'leaderboards',
);

const defaultState = Map({
  leaderboards: [],
  leaderboard: undefined,
  loading: false,
  error: undefined,
});

const defaults = {
  onLoading: () => ({ type: LEADERBOARDS.LOADING }),
  onSuccess: ({ data }) => ({ type: LEADERBOARDS.SUCCESS, data }),
  onFailure: (err) => ({ type: LEADERBOARDS.ERROR, err }),
};

export const getLeaderboards = () => requestAndDispatch({
  url: '1/qvp/leaderboards/withAchievements',
}, defaults);

export const getLeaderboard = (id) => requestAndDispatch({
  url: `1/qvp/leaderboards/${id}`,
  onSuccess: ({ data }) => ({ type: LEADERBOARDS.SUCCESS_SINGLE, data }),
}, defaults);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LEADERBOARDS.SUCCESS: {
      return state
        .set('loading', false)
        .set('leaderboards', action.data);
    }
    case LEADERBOARDS.SUCCESS_SINGLE: {
      return state
        .set('loading', false)
        .set('leaderboard', action.data);
    }
    case LEADERBOARDS.LOADING: {
      return state
        .set('loading', true);
    }
    case LEADERBOARDS.ERROR: {
      return state
        .set('loading', false)
        .set('error', true);
    }
    default: {
      return state;
    }
  }
}
