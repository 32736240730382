import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from './auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { deleteAndDispatch, postAndDispatch, requestAndDispatch } from '../helpers';

const MFA = defineAction(
  'MFA',
  [LOADING, SUCCESS, ERROR, 'DELETED'],
  'mfa',
);

const defaultState = Map({
  list: Map(),
  loading: false,
  error: false,
});

const loaded = (data) => (
  { type: MFA.SUCCESS, data }
);

const deleted = (id) => (
  { type: MFA.DELETED, id }
);

const loading = () => (
  { type: MFA.LOADING }
);

const loadingError = (error) => (
  { type: MFA.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
  onSuccess: ({ data }) => loaded(data.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})),
};

export const getMfa = () => requestAndDispatch({
  url: '1/identity/mfa',
}, defaults);

export const createMfa = (post) => postAndDispatch({
  url: '1/identity/mfa',
  data: post,
  onSuccess: ({ data }) => loaded({ [data.id]: data }),
}, defaults);

export const updateMfa = (id, newData) => postAndDispatch({
  url: `1/identity/mfa/${id}`,
  data: newData,
  onSuccess: ({ data }) => loaded({ [data.id]: data }),
}, defaults);

export const verifyMfa = (post) => postAndDispatch({
  url: `1/identity/mfa/${post.id}/verify`,
  data: post,
  onSuccess: ({ data }) => loaded({ [data.id]: data }),
}, defaults);

export const deleteMfa = (id) => deleteAndDispatch({
  url: `1/identity/mfa/${id}`,
  onSuccess: () => deleted(id),
}, defaults);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case MFA.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', false)
        .set('list', state.get('list').mergeDeep(action.data));
    }
    case MFA.DELETED: {
      return state
        .set('loading', false)
        .set('error', false)
        .deleteIn(['list', `${action.id}`]);
    }
    case MFA.LOADING: {
      return state
        .set('error', false) // Clear the previous error
        .set('loading', true);
    }
    case MFA.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case AUTH.LOGOUT:
      return state
        .set('loading', false)
        .set('error', false)
        .set('data', undefined);
    default: {
      return state;
    }
  }
}
