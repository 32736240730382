import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { BottomModal } from '../../../components/Modal';
import { extendLanguage, getFlagImage } from '../../../../../helpers';
import styles from './stream-picker.scss';

const propTypes = {
  streams: PropTypes.arrayOf(PropTypes.object),
  onChangeStream: PropTypes.func,
  activeStream: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    language: PropTypes.string,
  }),
};

class StreamPicker extends React.Component {
  constructor() {
    super();
    this.state = {
      streamsOpen: false,
    };

    this.toggleStreams = this.toggleStreams.bind(this);
  }

  UNSAFE_componentWillReceiveProps() {
    this.setState({
      streamsOpen: false,
    });
  }

  toggleStreams() {
    this.setState((state) => ({
      streamsOpen: !state.streamsOpen,
    }));
  }

  render() {
    const { activeStream, streams, onChangeStream } = this.props;
    const { streamsOpen } = this.state;

    return (
      <div
        role="button"
        tabIndex={0}
        className={styles.pickerContainer}
        onClick={this.toggleStreams}
      >
        { activeStream
          && (
          <div className={styles.picker}>
            <div className={styles.streamingFrom}>
              Streaming in
            </div>
            <div
              className={classNames(styles.stream, styles.activeStream)}
            >
              { getFlagImage(activeStream.language)
                && (
                <img
                  className={styles.flag}
                  alt={activeStream.language}
                  src={getFlagImage(activeStream.language)}
                />
                )}
              {extendLanguage(activeStream.language)}
              { activeStream.name !== extendLanguage(activeStream.language) && ` (${activeStream.name})` }
              { streams?.length > 1
                && <i className="far fa-exchange" />}
            </div>
          </div>
          )}
        { streams?.length > 1
          && (
          <BottomModal
            isOpen={streamsOpen}
            onRequestClose={this.toggleStreams}
            shouldCloseOnOverlayClick
            buttonText="Close"
          >
            <div className={styles.alternateStreams}>
              { streams.map((stream, i) => (
                <div
                  key={stream.id}
                  role="button"
                  tabIndex={i + 1}
                  className={classNames(
                    styles.alternateStream,
                    { [`${styles.active}`]: activeStream.id === stream.id },
                  )}
                  onClick={() => onChangeStream(stream)}
                >
                  { getFlagImage(stream.language)
                    && (
                    <img
                      className={styles.flag}
                      alt={stream.language}
                      src={getFlagImage(stream.language)}
                    />
                    )}
                  {extendLanguage(stream.language)}
                  { stream.name !== extendLanguage(stream.language) && ` (${stream.name})` }
                </div>
              ))}
            </div>
          </BottomModal>
          )}
      </div>
    );
  }
}

StreamPicker.propTypes = propTypes;

export default StreamPicker;
