import { accountLogout } from 'ducks/user/auth';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import MetaTags from 'components/MetaTags';
import PropTypes from 'prop-types';
import React from 'react';
import dhid from 'dhid';

import AuthBox from '../components/AuthBox';
import styles from '../account.scss';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([
    PropTypes.shape({
      json: PropTypes.object,
      message: PropTypes.string,
    }),
    PropTypes.bool,
  ]),
  authError: PropTypes.shape({
    message: PropTypes.string,
  }),
};

class Logout extends React.Component {
  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(accountLogout()).then(() => {
      window.location.pathname = '/';
    });
  }

  render() {
    const {
      error,
      authError,
    } = this.props;

    return (
      <AuthBox>
        <MetaTags
          title="DreamHack – Logout"
          titleSuffix={false}
        />
        {
          error && (
            <div className={styles.error}>
              {error.message}
            </div>
          )
        }
        {
          authError && (
            <div className={styles.error}>
              {authError.message}
            </div>
          )
        }
        {
          !dhid && (
            <div className={styles.error}>
              The DreamHack accounts is currently unavailable. Please try again later.
            </div>
          )
        }
        <h1 className={styles.title}>
          Log out in progress
        </h1>
      </AuthBox>
    );
  }
}

Logout.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: state.getIn(['user', 'loading']) || state.getIn(['auth', 'loading']),
    error: state.getIn(['user', 'error']),
    authError: state.getIn(['auth', 'error']),
  };
}

export default withRouter(connect(mapStateToProps)(Logout));
