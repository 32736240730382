import * as Sentry from '@sentry/browser';
import { render } from 'react-dom';
import React from 'react';

import App from './App';

/* global RELEASE DSN */
if (RELEASE !== 'dev') {
  Sentry.init({
    dsn: DSN,
    release: RELEASE,
    ignoreErrors: [
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      'Can\'t find variable: ZiteReader',
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this.
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',

      // Our own
      'Network Error',
      'Abort due to cancellation of share',
      'Failed to fetch',
      'Request aborted',
      'Error serializing unrecognized object Error: Network Error',
      'timeout of 0ms exceeded̈́',
    ],
    blacklistUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
  });
  Sentry.configureScope((scope) => scope.setTag('side', 'client'));
}

// Temporary: remove all dangling service workers
try {
  navigator.serviceWorker.getRegistrations().then((regs) => regs.map((sw) => sw.unregister()));
} catch (e) {
  /* eslint-disable no-empty */
}

render(
  <App />,
  document.getElementById('app'),
);
