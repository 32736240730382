import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import queryString from 'query-string';

import AuthBox from '../components/AuthBox';
import MetaTags from '../../../components/MetaTags';
import dhid from '../../../dhid';
import styles from '../account.scss';
import withUserRedirect from '../../../components/withUserRedirect';

const oAuthErrors = {
  no_email: 'Your account has no email address. You can only log in through a third party account with an email address.',
};

const propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
  }),
  authError: PropTypes.shape({
    message: PropTypes.string,
    json: PropTypes.shape({}),
  }),
  userError: PropTypes.shape({
    message: PropTypes.string,
    json: PropTypes.shape({}),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      returnHash: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

class LoginRedirect extends React.PureComponent {
  render() {
    const {
      location,
      authError,
      userError,
    } = this.props;

    const queryParams = location && location.search && queryString.parse(location.search);
    const oAuthError = queryParams && queryParams.error;
    if (oAuthError) {
      console.error(oAuthError);
    }

    return (
      <AuthBox>
        <MetaTags
          title="DreamHack – Login"
          titleSuffix={false}
        />
        {
          userError && (
            <div className={styles.error}>
              {userError.message}
            </div>
          )
        }
        {
          authError && (
            <div className={styles.error}>
              {authError.message}
            </div>
          )
        }
        {
          oAuthError && (
            <div className={styles.error}>
              {oAuthErrors[oAuthError] || 'An unexpected error occurred, try again!'}
            </div>
          )
        }
        {
          !dhid && typeof window !== 'undefined' && (
            <div className={styles.error}>
              The DreamHack login is currently unavailable. Please try again later.
            </div>
          )
        }
      </AuthBox>
    );
  }
}

LoginRedirect.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: state.getIn(['user', 'loading']) || state.getIn(['auth', 'loading']),
    userError: state.getIn(['user', 'error']),
    authError: state.getIn(['auth', 'error']),
  };
}

export default withUserRedirect(connect(mapStateToProps)(LoginRedirect));
