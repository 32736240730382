import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { accountLogout } from '../../ducks/user/auth';
import { update as updateApp } from '../../ducks/app';
import { getPrimus } from './helpers';

const propTypes = {
  children: PropTypes.node,
  dispatch: PropTypes.func,
  user: PropTypes.shape(),
};

const Context = React.createContext();

/* Context and provider */
class Websocket extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientRelease: RELEASE, // eslint-disable-line no-undef
      newRelease: false,
      online: null,
      channels: [],
    };
  }

  componentDidMount() {
    const primus = getPrimus();

    this.join('persistent:/releases/dhcom', this.onReleaseData);
    this.join('persistent:/settings/dhcom', this.onSettingsData);

    if (primus) {
      primus.on('open', () => {
        this.setState({ online: true });
      });
      primus.on('close', () => {
        this.setState({ online: false });
      });

      // Add the token everytime we connect. This is done everytime because we
      // reset the connecton everytime the user logs in or out
      primus.on('outgoing::url', (url) => {
        /* eslint-disable */
        url.query = url.query.concat(
          dhid.getToken() ? `&auth=${dhid.getToken()}` : ""
        );
        /* eslint-enable */
      });

      primus.on('ready', () => {
        // Rejoin all channels on connect
        const { channels } = this.state;
        channels.forEach((channel) => primus.send('subscribe', channel.channel));
      });

      primus.on('deauthorized', () => {
        this.props.dispatch(accountLogout('You were logged out from another location', true));
      });

      primus.on('data', ({ data }) => {
        const { channels } = this.state;
        if (data[0] === 'update') { // Special case for updates
          channels
            .filter((chan) => chan.channel === `${data[0]}s:${data[1]}`)
            .forEach(({ callback }) => callback(data[1]));
        } else {
          channels
            .filter((chan) => chan.channel === data[0])
            .forEach(({ callback }) => callback(data[1]));
        }
      });
    }
  }

  componentDidUpdate(prevProps) {
    // Reconnect websocket (to change authorization) everytime the user logs in or out
    if (prevProps.user !== this.props.user) {
      const primus = getPrimus();
      primus.end().open();
    }
  }

  join = (channel, callback) => {
    this.setState(({ channels }) => ({
      channels: [...channels, { channel, callback }],
    }));

    const primus = getPrimus();
    if (primus && primus.readyState === 3) {
      primus.send('subscribe', channel);
    }
  };

  leave = (channel) => {
    this.setState(({ channels }) => ({
      channels: channels.filter((chan) => chan !== channel),
    }));
    const primus = getPrimus();
    if (primus && primus.readyState === 3) {
      primus.send('unsubscribe', channel);
    }
  };

  onReleaseData = (data) => {
    this.setState(({ clientRelease }) => ({
      newRelease:
        data.release !== 'dev'
        && clientRelease !== 'dev'
        && data.release !== clientRelease,
      serverRelease: data.release,
    }));
  };

  onSettingsData = (data) => {
    this.props.dispatch(updateApp({ settings: data }));
  };

  render() {
    const { children } = this.props;
    return (
      <Context.Provider value={{ ...this.state, join: this.join, leave: this.leave }}>
        {children}
      </Context.Provider>
    );
  }
}

Websocket.propTypes = propTypes;

const mapStateToProps = (state) => ({
  user: state.getIn(['user', 'data']),
});

export { Context };
export default connect(mapStateToProps)(Websocket);
