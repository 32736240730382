import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import {
  deleteAndDispatch,
  postAndDispatch,
  putAndDispatch,
  requestAndDispatch,
} from '../helpers';

const CREW_FEED = defineAction(
  'CREW_FEED',
  [LOADING, SUCCESS, ERROR, 'DELETED'],
  'crew_feed',
);

const defaultState = Map({
  loading: false,
  list: Map(),
  error: undefined,
  permissions: Map(),
});

// Events
export const loaded = (data, permissions) => (
  { type: CREW_FEED.SUCCESS, data, permissions }
);

export const deleted = (id) => (
  { type: CREW_FEED.DELETED, id }
);

const loading = () => (
  { type: CREW_FEED.LOADING }
);

const loadingError = (error) => (
  { type: CREW_FEED.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
};

export const getFeed = () => requestAndDispatch({
  url: '1/crew/feed',
  onSuccess: ({ data, headers }) => loaded(
    data,
    JSON.parse(headers['x-permissions'] || '{}'),
  ),
}, defaults);

export const getGuide = () => requestAndDispatch({
  url: '1/crew/feed/guide',
  onSuccess: ({ data, headers }) => loaded(
    data,
    JSON.parse(headers['x-permissions'] || '{}'),
  ),
}, defaults);

export const getFeedForEvent = (event) => requestAndDispatch({
  url: `1/crew/feed/event/${event}`,
  onSuccess: ({ data, headers }) => loaded(
    data,
    { [event]: JSON.parse(headers['x-permissions'] || '{}') },
  ),
}, defaults);

export const getFeedForTeam = (team) => requestAndDispatch({
  url: `1/crew/feed/team/${team}`,
  onSuccess: ({ data, headers }) => loaded(
    data,
    { [team]: JSON.parse(headers['x-permissions'] || '{}') },
  ),
}, defaults);

export const postToFeed = (postData) => postAndDispatch({
  url: '1/crew/feed',
  data: postData,
  onSuccess: ({ data }) => loaded({
    [data.id]: data,
  }),
}, defaults);

export const updatePost = (id, postData) => putAndDispatch({
  url: `1/crew/feed/${id}`,
  data: postData,
  onSuccess: ({ data }) => loaded({
    [data.id]: data,
  }),
}, defaults);

export const removePost = (id) => deleteAndDispatch({
  url: `1/crew/feed/${id}`,
  onSuccess: () => deleted(id),
}, defaults);


export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREW_FEED.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case CREW_FEED.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case CREW_FEED.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('list', state.get('list').mergeDeep(action.data))
        .set('permissions', state.get('permissions').mergeDeep(action.permissions));
    }
    case CREW_FEED.DELETED: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .deleteIn(['list', action.id]);
    }
    case AUTH.LOGOUT:
      return defaultState;
    default: {
      return state;
    }
  }
}
