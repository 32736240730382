import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';
import moment from 'moment';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import {
  putAndDispatch,
  requestAndDispatch,
} from '../helpers';

const CREW_NOTIFICATIONS = defineAction(
  'CREW_NOTIFICATIONS',
  [LOADING, SUCCESS, ERROR, 'SHOWN', 'OPENED'],
  'crew_notifications',
);

const defaultState = Map({
  loading: false,
  list: Map(),
  error: undefined,
  new: false,
});

// Events
export const loaded = (data) => (
  { type: CREW_NOTIFICATIONS.SUCCESS, data }
);

const loading = () => (
  { type: CREW_NOTIFICATIONS.LOADING }
);

const loadingError = (error) => (
  { type: CREW_NOTIFICATIONS.ERROR, error }
);

const shown = () => (
  { type: CREW_NOTIFICATIONS.SHOWN }
);

const opened = (id) => (
  { type: CREW_NOTIFICATIONS.OPENED, id }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
};

// Actions
export const getNotifications = () => requestAndDispatch({
  url: '1/crew/notifications',
  onSuccess: ({ data }) => loaded(
    data,
  ),
}, defaults);

export const markAllAsRead = () => putAndDispatch({
  url: '1/crew/notifications/read',
  onSuccess: ({ data }) => loaded(
    data,
  ),
}, defaults);

export const openedNotification = (id) => putAndDispatch({
  url: `1/crew/notifications/${id}/read`,
  onLoading: () => opened(id),
  onSuccess: ({ data }) => loaded(
    data,
  ),
}, defaults);

export const shownNotifications = () => (dispatch) => {
  dispatch(shown());
};

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREW_NOTIFICATIONS.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case CREW_NOTIFICATIONS.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case CREW_NOTIFICATIONS.SUCCESS: {
      const n = Object.values(action.data).find((item) => item.read_at === null) !== undefined;

      return state
        .set('loading', false)
        .set('error', undefined)
        .set('list', state.get('list').mergeDeep(action.data))
        .set('new', n);
    }
    case CREW_NOTIFICATIONS.SHOWN: {
      return state
        .set('new', false);
    }
    case CREW_NOTIFICATIONS.OPENED: {
      return state
        .setIn(['list', action.id, 'read_at'], moment().toISOString());
    }
    case AUTH.LOGOUT:
      return defaultState;
    default: {
      return state;
    }
  }
}
