import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { CREW_TEAM } from './team';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { USER } from '../user/user';
import { createOnlyOnceCondition } from '../conditions';
import { postAndDispatch, requestAndDispatch } from '../helpers';
import dhid from '../../dhid';

const USERS = defineAction(
  'crew_users',
  [LOADING, SUCCESS, ERROR, 'LOADING_SEARCH', 'SUCCESS_SEARCH', 'SUCCESS_USERDATA'],
  'users',
);

const defaultState = Map({
  list: Map(),
  userData: Map(),
  loading: false,
  requests: 0,
  error: false,
  searchQuery: '',
  searchResult: Map(),
});

const loaded = (data) => (
  { type: USERS.SUCCESS, data }
);

const loadedSearch = (data) => (
  { type: USERS.SUCCESS_SEARCH, data }
);

const loadedUserData = (data) => (
  { type: USERS.SUCCESS_USERDATA, data }
);

const loading = () => (
  { type: USERS.LOADING }
);

const loadingSearch = (query) => (
  { type: USERS.LOADING_SEARCH, query }
);

const loadingError = (error) => (
  { type: USERS.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
  onSuccess: ({ data }) => loaded(data),
};

const { condition: onlyOnce, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();
export const getUsers = (ids) => postAndDispatch({
  url: '1/identity/users/search',
  data: {
    id: onlyOnce(ids),
  },
}, defaults);

const {
  condition: onlyOnceComplete,
  clear: clearOnlyOnceCompleteCondition,
} = createOnlyOnceCondition();

export const getUsersComplete = (ids) => postAndDispatch({
  url: '1/identity/users/search',
  data: {
    id: onlyOnceComplete(ids),
  },
}, defaults);

export function searchUsers(query) {
  return (dispatch) => {
    dispatch(loadingSearch());
    return dhid.post('1/identity/users/search', {
      query,
      with: {
        crew: true,
      },
    })
      .then(({ data }) => {
        dispatch(loadedSearch(data));
        return data;
      })
      .catch((error) => {
        dispatch(loadingError(error));
        throw error;
      });
  };
}

export const getUserData = (user_id, keys) => requestAndDispatch({
  url: `1/identity/users/${user_id}/data`,
  params: { keys },
  onSuccess: ({ data }) => loadedUserData(data),
  condition: onlyOnce,
}, {});

export const getSearchUserData = (users, keys) => postAndDispatch({
  url: '1/identity/users/data',
  data: {
    id: users,
    keys,
  },
  onSuccess: ({ data }) => loadedUserData(data),
  condition: onlyOnce,
}, {});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USERS.LOADING: {
      return state
        .set('error', false) // Clear the previous error
        .set('requests', state.get('requests') + 1)
        .set('loading', true);
    }
    case USERS.LOADING_SEARCH: {
      return state
        .set('error', false) // Clear the previous error
        .set('loading', true)
        .set('requests', state.get('requests') + 1)
        .set('searchQuery', action.query);
    }
    case USERS.ERROR: {
      return state
        .set('loading', false)
        .set('requests', state.get('requests') - 1)
        .set('searchResult', fromJS({}))
        .set('error', serializeError(action.error));
    }
    case USERS.SUCCESS: {
      return state
        .set('loading', state.get('requests') > 1)
        .set('requests', state.get('requests') - 1)
        .set('error', false)
        .set('list', state.get('list').mergeDeep(action.data));
    }
    case USERS.SUCCESS_SEARCH: {
      return state
        .set('loading', state.get('requests') > 1)
        .set('requests', state.get('requests') - 1)
        .set('error', false)
        .set('searchResult', fromJS(action.data))
        .set('list', state.get('list').mergeDeep(action.data));
    }
    case USER.SAVE_SUCCESS: {
      if (action.data) {
        return state
          .set('list', state.get('list').mergeDeep({
            [action.data.id]: action.data,
          }));
      }
      return state;
    }

    case USERS.SUCCESS_USERDATA: {
      return state
        .set('userData', state.get('userData').mergeDeep(action.data));
    }
    case CREW_TEAM.SUCCESS: {
      const map = {}; // make a list of users received
      const extractUsers = (memberships) => {
        if (memberships) {
          memberships.forEach((membership) => {
            if (membership.member_type === 'App\\Models\\User') {
              map[membership.member.id] = membership.member;
              onlyOnce(membership.member.id)(); // Flag that it already exists
            }
          });
        }
      };
      if (action.data) {
        action.data.forEach((item) => {
          const { memberships, children, descendants } = item;
          extractUsers(memberships);

          if (children) {
            children.forEach((child) => {
              extractUsers(child.memberships);
            });
          }
          if (descendants) {
            descendants.forEach((child) => {
              extractUsers(child.memberships);
            });
          }
        });
      }

      return state
        .set('list', state.get('list').mergeDeep(map));
    }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      clearOnlyOnceCompleteCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
