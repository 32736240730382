// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-Crew-loader__loading--2RdOv{display:flex;align-content:center;align-items:center}.src-routes-Crew-loader__loading--2RdOv>div{width:100%}.src-routes-Crew-loader__loading--2RdOv .src-routes-Crew-loader__error--2XGkB{color:#fff;margin-left:auto;margin-right:auto;width:auto;padding:20px;background:#f60;min-with:50%;max-width:90%;text-align:center}.src-routes-Crew-loader__loading--2RdOv .src-routes-Crew-loader__error--2XGkB>pre{margin:0;margin-top:10px;overflow:auto;background:#eee;color:#000;padding:10px;text-align:left}\n", ""]);
// Exports
exports.locals = {
	"loading": "src-routes-Crew-loader__loading--2RdOv",
	"error": "src-routes-Crew-loader__error--2XGkB"
};
module.exports = exports;
