import { Map } from 'immutable';
import { defineAction } from 'redux-define';

import { ERROR, LOADING } from './stateConstants';
import { getDeviceToken, isMobile, notificationPermission } from '../mobile/helpers';
import dhid from '../dhid';

const NOTIFICATIONS = defineAction(
  'NOTIFICATIONS',
  [LOADING, 'ADD', 'REMOVE', ERROR],
  'notifications',
);

const defaultState = Map({
  notifications: {},
  loading: false,
  error: false,
  increment: 1,
});

export const add = (id, increment) => (
  { type: NOTIFICATIONS.ADD, id, increment }
);

export const remove = (id) => (
  { type: NOTIFICATIONS.REMOVE, id }
);

const loading = () => (
  { type: NOTIFICATIONS.LOADING }
);

export function addNotification(options) {
  const {
    id,
    type,
    language = 'en',
    offset = 0,
    ignoreFail = false,
    userId,
  } = options;
  return async (dispatch) => {
    // Check if native, and handle permission if there is no permission yet.
    // This could be because it's the first time adding a notification on iOS,
    // or if the user has disabled notifications in another way.
    if (isMobile() && !(await notificationPermission())) {
      return window.FirebasePlugin.grantPermission(
        // Permission was granted, run this function again. It should now
        // skip this step and go to adding the notification.
        (granted) => {
          if (granted) {
            return dispatch(addNotification(options));
          } else {
            if (!ignoreFail) {
              navigator.notification.alert(
                'Seems you\'ve disabled notifications for DreamHack. Please activate notifications for DreamHack in your system settings and try again.',
                () => null,
                'Woops!',
                'Got it',
              );
            }
            return false;
          }
        },
        // Permission was not granted, show a message indicating that.
        () => {
          // In case you already handle this some other way (like showing your
          // own message), the ignoreFail bool skips the message.
          if (!ignoreFail) {
            navigator.notification.alert(
              'Seems you\'ve disabled notifications for DreamHack. Please activate notifications for DreamHack in your system settings and try again.',
              () => null,
              'Woops!',
              'Got it',
            );
          }
          return false;
        },
      );
    }

    // We only get here if we have permission (or if we're debugging on desktop)
    dispatch(loading());
    const user_id = userId || dhid.getUserID();
    const token = isMobile() ? await getDeviceToken() : 'DESKTOP_DEBUG';
    return dhid.request(`/1/content/notifications/subscribe/${user_id ? 'dhid' : 'anon'}`, {
      method: 'post',
      data: {
        device_token: token,
        entity_id: id,
        type,
        language,
        offset,
      },
    })
      .then(({ data }) => {
        // Keeps a registry of notifications locally as well, for example to
        // indicate if there is a notification for an entity or not.
        dispatch(add(data.entity_id, data.topic));
        return true;
      })
      .catch((err) => {
        dispatch({ type: NOTIFICATIONS.ERROR, err });
        return false;
      });
  };
}

export function removeNotification(id) {
  return async (dispatch) => {
    dispatch(loading());
    const token = isMobile() ? await getDeviceToken() : 'DESKTOP_DEBUG';
    return dhid.request('/1/content/notifications/unsubscribe', {
      method: 'post',
      data: {
        device_token: token,
        entity_id: id,
      },
    })
      .then(() => {
        dispatch(remove(id));
      })
      .catch((err) => {
        dispatch({ type: NOTIFICATIONS.ERROR, err });
      });
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case NOTIFICATIONS.ADD: {
      const { id, increment } = action;
      const notifications = state.get('notifications');
      return state
        .set('notifications', {
          ...notifications,
          [id]: increment,
        })
        .set('increment', increment)
        .set('loading', false);
    }
    case NOTIFICATIONS.REMOVE: {
      const notifications = state.get('notifications');
      const { [action.id]: deleted, ...newNotifications } = notifications;
      return state
        .set('notifications', newNotifications)
        .set('loading', false);
    }
    case NOTIFICATIONS.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case NOTIFICATIONS.ERROR: {
      return state
        .set('loading', false)
        .set('error', action.err);
    }
    default: {
      return state;
    }
  }
}
