import { Map } from 'immutable';
import { applyMiddleware, compose, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import dhid from 'dhid';
import * as Sentry from '@sentry/browser';
import createSentryMiddleware from 'redux-sentry-middleware';

// import logger from 'redux-logger';
import rootReducer from './ducks';

const middleware = [
  ReduxThunk,
  createSentryMiddleware(Sentry, {
    getUserContext: () => dhid.getUser(),
  }),
  // logger,
];

/* eslint-disable no-underscore-dangle */
const composeEnhancers = (
  typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
) || compose;
/* eslint-enable */

const newStore = (initialState = Map(), extraMiddlewares = []) => createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(
    ...extraMiddlewares,
    ...middleware,
  )),
);
const store = newStore();

export default store;
export { newStore };
