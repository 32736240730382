import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { createOnlyOnceCondition } from '../conditions';
import {
  postAndDispatch,
  requestAndDispatch,
} from '../helpers';

const CREW_CHECKINS = defineAction(
  'CREW_CHECKINS',
  [LOADING, SUCCESS, ERROR, 'CHECKIN_USER', 'CHECKIN_USER_SUCCES'],
  'crew_checkins',
);

const defaultState = Map({
  loading: false,
  list: Map(),
  error: undefined,
  permissions: Map(),
});

// Events
export const loaded = (data, permissions) => (
  { type: CREW_CHECKINS.SUCCESS, data, permissions }
);

const loading = () => (
  { type: CREW_CHECKINS.LOADING }
);

const loadingError = (error) => (
  { type: CREW_CHECKINS.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
};

const { condition: onlyOnce, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();

// Actions
export const getCheckins = (user_id) => requestAndDispatch({
  url: `1/crew/checkins/user/${user_id}`,
  onSuccess: ({ data, headers }) => loaded(
    data,
    JSON.parse(headers['x-permissions'] || '{}'),
  ),
  condition: onlyOnce,
}, defaults);

export const getCheckinsForEvent = (event_id) => requestAndDispatch({
  url: `1/crew/checkins/event/${event_id}`,
  onSuccess: ({ data, headers }) => loaded(
    Object.keys(data).reduce((acc, key) => ({
      ...acc,
      [key]: {
        user_id: data[key],
        event_id,
      },
    }), {}),
    JSON.parse(headers['x-permissions'] || '{}'),
  ),
  condition: onlyOnce,
}, defaults);

export const checkin = (user_id, event_id) => postAndDispatch({
  url: '1/crew/checkins',
  data: {
    user_id,
    event_id,
  },
  onSuccess: ({ data }) => loaded({
    [data.id]: data,
  }),
}, defaults);

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREW_CHECKINS.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case CREW_CHECKINS.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case CREW_CHECKINS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('list', state.get('list').mergeDeep(action.data))
        .set('permissions', state.get('permissions').mergeDeep(action.permissions));
    }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
