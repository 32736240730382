import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import React from 'react';

import { DEFAULT_META_IMAGE, SITE_DESCRIPTION, SITE_NAME } from '../config';

const propTypes = {
  title: PropTypes.string,
  titleSuffix: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
  ]),
  description: PropTypes.string,
  index: PropTypes.bool,
  image: PropTypes.string,
};

const getPageTitle = (title, suffix) => (
  `${title} ${suffix ? `· ${suffix}` : ''}`
);

const MetaTags = ({
  title,
  titleSuffix,
  description,
  image,
  index,
}) => (
  <Helmet>
    <title>{getPageTitle(title, titleSuffix)}</title>
    <meta name="description" content={description} />
    <meta property="og:title" content={getPageTitle(title, titleSuffix)} />
    <meta property="og:site_name" content={SITE_NAME} />
    <meta property="og:description" content={description} />
    <meta property="og:image" content={image} />
    <meta name="google-site-verification" content="6b_1bREXJVB3Gmha_FAO3RremkF94HnlpPDLzoqfCyc" />
    <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
    <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
    { !index && <meta name="robots" content="noindex" /> }
  </Helmet>
);

const defaultProps = {
  title: SITE_NAME,
  description: SITE_DESCRIPTION,
  image: DEFAULT_META_IMAGE,
  index: true,
};

MetaTags.defaultProps = defaultProps;
MetaTags.propTypes = propTypes;

export default MetaTags;
