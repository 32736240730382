import { Redirect, Route, Switch } from 'react-router-dom';
import RavenErrorBoundary from 'components/RavenErrorBoundary';
import React from 'react';
import universal from 'react-universal-component';

import {
  Authorize,
  Cookies,
  DataViewerLoader,
  Login,
  Logout,
} from './routes/Account';
import AppLoader from './routes/App/loader';
import CrewLoader from './routes/Crew/loader';
import PressLoader from './routes/Press/loader';
import Watch from './routes/App/Watch';
import LoginRedirect from './routes/Account/Login/LoginRedirect';

const Seatmap = universal(import(/* webpackChunkName: 'tickets' */ './routes/App/Seatmap'));

const Routes = () => (
  <RavenErrorBoundary>
    <Switch>
      <Route path="/crew" component={CrewLoader} />
      <Route path="/accreditation" component={PressLoader} />
      <Route path="/tickets/:eventId?" component={Seatmap} />
      <Route path="/app" component={AppLoader} />
      <Route path="/frame" component={AppLoader} />
      <Route path="/login/redirect/:returnHash?" component={LoginRedirect} />
      <Route path="/login/:returnHash?" component={Login} />
      <Route path="/account/settings" component={() => <Redirect to="/app/me" />} />
      <Route path="/account/authorize" component={Authorize} />
      <Route path="/account/export/:assetId" component={DataViewerLoader} />
      <Route path="/cookies" component={Cookies} />
      <Route path="/tv" render={() => <Watch clean />} />
      <Route exact path="/logout/:returnUrl?" component={Logout} />
      <Route path="/" component={() => <Redirect to="/app" />} />
    </Switch>
  </RavenErrorBoundary>
);

export default Routes;
