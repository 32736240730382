import { Map } from 'immutable';
import { defineAction } from 'redux-define';

import dhid from '../dhid';
import { ERROR, LOADING, SUCCESS } from './stateConstants';

const STREAMS = defineAction(
  'STREAMS',
  [LOADING, SUCCESS, ERROR],
  'streams',
);

const defaultState = Map({
  streams: [],
  loading: false,
  error: false,
  loaded: false,
});

const streamsList = (streams) => (
  { type: STREAMS.SUCCESS, streams }
);

const loading = () => (
  { type: STREAMS.LOADING }
);

const loadingError = () => (
  { type: STREAMS.ERROR }
);

export function getStreams() {
  return (dispatch) => {
    dispatch(loading());

    return dhid.get('1/content/streams/public')
      .then(({ data }) => {
        const streams = Object.values(data).slice().sort((a, b) => {
          if (a.sort === b.sort) {
            return Math.random() < 0.5 ? -1 : 1;
          }
          return a.sort - b.sort;
        });
        dispatch(streamsList(streams));
        return streams;
      })
      .catch(() => (
        dispatch(loadingError())
      ));
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case STREAMS.SUCCESS: {
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('streams', action.streams);
    }
    case STREAMS.LOADING: {
      return state
        .set('loading', true);
    }
    case STREAMS.ERROR: {
      return state
        .set('loading', false)
        .set('error', true);
    }
    default: {
      return state;
    }
  }
}
