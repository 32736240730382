import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { getStreams } from '../../../ducks/streams';
import Content from '../components/Content';
import Header from '../components/Header';
// import { withRouter } from 'react-router-dom';
import MetaTags from '../../../components/MetaTags';
// import Chat from './components/Chat';
import Stream from './components/Stream';
import StreamPicker from './components/StreamPicker';
import Subheader from '../components/Subheader';
import TourPicker from './components/TourPicker';
import metaImage from '../../../assets/dhtv.png';

const propTypes = {
  streams: PropTypes.arrayOf(PropTypes.object),
  clean: PropTypes.bool,
  dispatch: PropTypes.func.isRequired,
};

const randomSort = Math.random() < 0.5 ? -1 : 1;

// Extract all top level streams (no parent) from a list of streams
const extractTours = (streams) => (
  streams.filter((stream) => (
    (!stream.parent)
  ))
);

// Extract self and all children (i.e. streams with self as parent)
// and sort by index.
const extractChildren = (parent, streams) => (
  streams.filter((stream) => (
    (stream.parent_id === parent.id || stream.id === parent.id)
  )).slice().sort((a, b) => {
    if (a.sort === b.sort) {
      return randomSort;
    }
    return a.sort - b.sort;
  })
);

class Watch extends React.Component {
  constructor() {
    super();

    this.state = {
      activeStream: {},
      activeTour: {},
      childStreams: [],
      toursHidden: false,
      chatHidden: true,
    };
  }

  componentDidMount() {
    const { dispatch } = this.props;
    dispatch(getStreams()).then((streams) => {
      if (streams && streams?.length > 0) {
        this.setActiveTour(streams[0]);
      }
    });
  }

  setActiveStream = (stream) => {
    this.setState({
      activeStream: stream,
    });
  }

  setActiveTour = (tour) => {
    const { streams } = this.props;
    const childStreams = extractChildren(tour, streams);
    this.setActiveStream(childStreams[0]);
    this.setState({
      childStreams,
      activeTour: tour,
    });
  }

  toggleTours = () => {
    const { toursHidden, chatHidden } = this.state;
    this.setState({
      toursHidden: !toursHidden,
      chatHidden: toursHidden ? true : chatHidden,
    });
  }

  toggleChat = () => {
    const { chatHidden, toursHidden } = this.state;
    this.setState({
      chatHidden: !chatHidden,
      toursHidden: chatHidden ? true : toursHidden,
    });
  }

  render() {
    const {
      activeStream,
      activeTour,
      childStreams,
      toursHidden,
      // chatHidden,
    } = this.state;
    const { clean, streams } = this.props;
    const tours = extractTours(streams);
    return (
      <>
        { !clean
          && (
          <Header>
            Watch
          </Header>
          )}
        <Content>
          { clean
            && (
            <MetaTags
              title="Live TV"
              titleSuffix="DreamHack"
              description="Watch all the DreamHack and partner tournaments live through DreamHack.tv! Select the game and language you want to watch in one place!"
              image={metaImage}
            />
            )}
          <Subheader>
            <StreamPicker
              streams={childStreams}
              activeStream={activeStream}
              onChangeStream={this.setActiveStream}
            />
          </Subheader>
          <Stream
            {...activeStream}
          />
          { /*
            <Chat
              activeStream={activeStream}
              onToggle={this.toggleChat}
              hidden={chatHidden}
            />
          */ }
          <TourPicker
            tours={tours}
            activeTour={activeTour}
            onChangeTour={this.setActiveTour}
            onToggle={this.toggleTours}
            hidden={toursHidden}
          />
        </Content>
      </>
    );
  }
}

Watch.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    streams: state.getIn(['streams', 'streams']),
    error: state.getIn(['streams', 'error']),
    loading: state.getIn(['streams', 'loading']),
  };
}

export default connect(mapStateToProps)(Watch);
