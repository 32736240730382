import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import { extendGame, getImageUrl } from '../../../../../helpers';
import { isMobile } from '../../../../../mobile/helpers';
import BackgroundImage from '../../../components/BackgroundImage';
import styles from './tour-picker.scss';

const propTypes = {
  tours: PropTypes.arrayOf(PropTypes.object),
  onChangeTour: PropTypes.func,
  activeTour: PropTypes.shape({
    id: PropTypes.string,
  }),
  onToggle: PropTypes.func.isRequired,
  hidden: PropTypes.bool,
};

const TourPicker = ({
  tours,
  onChangeTour,
  activeTour,
  hidden,
  onToggle,
}) => (
  <div className={styles.container}>
    <div className={styles.header}>
      <div className={styles.liveBroadcasts}>
        {tours?.length ? 'Live broadcasts today' : 'No live broadcasts'}
      </div>
      { tours?.length > 0
        && (
        <button
          className={classNames(
            styles.toggleTours,
            { [`${styles.hidden}`]: hidden },
          )}
          onClick={onToggle}
          type="button"
        >
          <i className="far fa-angle-double-down" />
        </button>
        )}
    </div>
    <div className={classNames(
      styles.tours,
      {
        [`${styles.noTours}`]: !tours?.length,
        [`${styles.hidden}`]: hidden,
      },
    )}
    >
      { tours.slice().sort((a, b) => b.sort < a.sort).map((tour) => (
        <BackgroundImage
          key={tour.id}
          onClick={() => onChangeTour(tour)}
          role="button"
          tabIndex={0}
          className={classNames(styles.tour)}
          src={tour.game_image && getImageUrl(tour.game_image)}
        >
          { tour.id !== activeTour.id
            && <div className={styles.tint} />}
          { tour.brand_image
            && (
            <div className={styles.brandImageContainer}>
              <img
                className={styles.brandImage}
                src={getImageUrl(tour.brand_image, 'original', 'png')}
                alt={tour.name}
              />
            </div>
            )}
          <div className={styles.title}>
            <h1>{tour.ip}</h1>
            <h2>{extendGame(tour.game)}</h2>
          </div>
        </BackgroundImage>
      ))}
      <div className={styles.seeMore}>
        <span>
          {'Read about upcoming events and tournaments on '}
          { isMobile()
            ? (
              <a
                role="button"
                onClick={() => window.open('https://dreamhack.com')}
                tabIndex={0}
              >
                DreamHack.com →
              </a>
            )
            : <Link to="/">DreamHack.com →</Link>}
        </span>
      </div>
    </div>
  </div>
);

TourPicker.propTypes = propTypes;

export default TourPicker;
