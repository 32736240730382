import * as Sentry from '@sentry/browser';
import moment from 'moment';

import { isMobile } from './mobile/helpers';
import dhid from './dhid'; // eslint-disable-line

export const formatDate = (start, end) => (
  `${moment(start).format('MMM D')} - ${moment(end).format('MMM D')}`
);

/* eslint-disable no-undef */
export const getImageUrl = (id, size = 'original', type) => id && (
  `${CDN_BASEURL}/images/${id}/${size}${typeof type === 'undefined' ? '' : `.${type}`}`
);
/* eslint-enable */

export const navigate = (history, path) => {
  if (path?.pathname) {
    history.push(path);
    return;
  }

  const newPath = path.replace(/\/+$/, '').replace('//', '/');
  console.log('navigate', history.location.pathname, '->', newPath); // , (new Error()).stack);
  history.push(newPath);
};
export const navigatePush = (history, path) => {
  if (!path) {
    console.warn('ignored empty push navigate');
  }

  const newPath = `${history.location.pathname.replace(/\/+$/, '')}/${path}`.replace('//', '/');
  console.log('push navigate', history.location.pathname, '->', newPath, '(', path, ')'); // , (new Error()).stack);
  history.push(newPath);
};

// Error handler for axios
export const formatDhidError = (error) => {
  // If the error has a response object with a status code, we can assume it's
  // an Axios error.
  if (error && error.response && error?.response?.status) {
    const { response } = error;
    const description = response.data.error_description ? `: ${response.data.error_description}` : '';
    const axiosError = new Error(response.data.error);
    axiosError.response = response;
    axiosError.status = response.status;
    if (!axiosError.json && axiosError.response.data) {
      axiosError.json = axiosError.response.data;
    }
    axiosError.message = `${response.data.error || response.data.message} ${description}`;
    const skipSentry = [422, 423, 401, 403, 404, 451].find((status) => status === response.status);
    if ([422, 423].indexOf(response.status) !== -1) {
      axiosError.message = 'We found some errors';
    }
    if (!skipSentry) {
      Sentry.captureException(axiosError, {
        extra: {
          response,
        },
      });
    } else {
      error.skipSentry = true; // eslint-disable-line
    }

    // The token is not valid anymore (probably).
    // I would like to dispatch here, but I can't access neither store nor dispatch(),
    // so lets just use the regular logout function (which will also clear the
    // session from safari, not that it matters but still :D) and after that we're
    // redirected to dreamhackapp://?logout=true which sets the user to empty in redux
    // and removes the invalid token from localStorage.
    if (response.status === 401 && isMobile() && window.logout && dhid && dhid.getToken()) {
      window.logout();
    }
    // I separate these because I'm lazy and to make sure the comments are for the right
    // part.
    // This handles the same as above, but for the browser.
    if (response.status === 401 && !isMobile() && dhid && dhid.getToken()) {
      const { pathname } = window.location;
      window.location = `/logout/${encodeURIComponent(btoa(pathname))}`;
    }

    throw axiosError;
  } else {
    if (error?.code === 'ECONNABORTED') {
      error.skipSentry = true; // eslint-disable-line
    }
    // Else, this is a JavaScript error, which already has a message property
    throw error;
  }
};

const extendedGameNames = {
  csgo: 'Counter-Strike: Global Offensive',
  dota2: 'Dota 2',
  hs: 'Hearthstone',
  hots: 'Heroes of the Storm',
};

export const extendGameName = (name) => (
  extendedGameNames[name] !== 'undefined'
    ? extendedGameNames[name] : name
);

const extendedLanguages = {
  sv: 'Svenska',
  de: 'German',
  en: 'English',
  pt: 'Portugese',
  cs: 'Czech',
  ru: 'Russian',
  pl: 'Polish',
  cn: 'Chinese',
  it: 'Italian',
  tr: 'Turkish',
  vi: 'Vietnamese',
  es: 'Español',
  fr: 'Française',
  fi: 'Finnish',
  no: 'Norwegian',
  ar: 'Arabic',
  bg: 'Bulgarian',
  ko: 'Korean',
  hu: 'Hungarian',
};

export const extendLanguage = (lang) => (
  typeof extendedLanguages[lang] !== 'undefined'
    ? extendedLanguages[lang] : lang
);

const extendedGames = {
  brawlhalla: 'Brawlhalla',
  csgo: 'Counter-Strike: Global Offensive',
  dota2: 'Dota 2',
  hearthstone: 'Hearthstone',
  hots: 'Heroes of the Storm',
  lol: 'League of Legends',
  pubg: 'PLAYERUNKNOWN\'s Battlegrounds',
  quakechamp: 'Quake Champions',
  quakelive: 'Quake Live',
  rocketleague: 'Rocket League',
  shadowverse: 'ShadowVerse',
  smite: 'Smite',
};

export const extendGame = (game) => (
  typeof extendedGames[game] !== 'undefined'
    ? extendedGames[game] : game
);

/* eslint-disable global-require */
/* eslint-disable import/no-dynamic-require */
export const getFlagImage = (lang) => {
  try {
    return require(`./assets/flags/${lang}.png`).default;
  } catch (e) {
    return '';
  }
};
/* eslint-enable */

export const sortPostsByDate = (a, b) => (
  moment(a.published_at).isBefore(moment(b.published_at))
);

export const ellipsis = (text, n, useBoundary) => {
  if (text.length <= n) {
    return text;
  }

  const subString = text.substr(0, n - 1);
  if (useBoundary) {
    const boundaryString = subString.substr(0, subString.lastIndexOf(' '));
    return `${boundaryString}…`;
  }

  return `${subString}…`;
};
