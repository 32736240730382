import * as Sentry from '@sentry/browser';
/* eslint-disable import/no-cycle */
// Above rule is because this imports from ./user.js remove and fix when you read this
import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { getCsrfToken } from '../helpers';
import { loaded as userLoaded } from './user';
import dhid from '../../dhid';

Sentry.configureScope((scope) => scope.setUser(dhid.getUser()));

export const AUTH = defineAction(
  'AUTH',
  [
    LOADING,
    SUCCESS,
    ERROR,
    'AUTHORIZATION_SUCCESS',
    'CLIENT_SUCCESS',
    'CLIENT_ERROR',
    'CLIENT_AUTHORIZED',
    'LOGOUT',
  ],
  'accountAuth',
);

const defaultState = Map({
  loading: false,
  error: undefined,
  authorization: undefined,
  client: undefined,
  clientAuthorized: false,
  clientLoaded: false,
});

const loaded = (data) => ({ type: AUTH.SUCCESS, data });

const loadedAutorization = (authorization) => ({
  type: AUTH.AUTHORIZATION_SUCCESS,
  authorization,
});

const loadedClient = (client) => ({ type: AUTH.CLIENT_SUCCESS, client });

const authorizedClient = () => ({ type: AUTH.CLIENT_AUTHORIZED });

const loading = () => ({ type: AUTH.LOADING });

const loadingError = (error) => ({ type: AUTH.ERROR, error });

const loadingClientError = (error) => ({ type: AUTH.CLIENT_ERROR, error });

const logout = (reason) => ({
  type: AUTH.LOGOUT,
  reason: reason ? new Error(reason) : null,
});

export function getAuthorization(queryString) {
  return (dispatch) => {
    dispatch(loading());
    dhid.get(`1/identity/client/authorize${queryString}`)
      .then(({ data }) => {
        dispatch(loadedAutorization(data));
      })
      .catch((error) => dispatch(loadingError(error)));
  };
}

export function authorize(queryString) {
  return (dispatch) => {
    dispatch(loading());
    dhid.request(`1/identity/client/authorize${queryString}`, {
      method: 'post',
    })
      .then(({ data }) => {
        dispatch(loadedAutorization(data));
      })
      .catch((error) => dispatch(loadingError(error)));
  };
}

export function attemptLogin(user) {
  return async (dispatch) => {
    dispatch(loading());
    const csrfToken = await getCsrfToken();
    return dhid.request(`${dhid.getBaseUrl()}/login`, {
      method: 'post',
      data: {
        ...user,
        csrfToken,
      },
      withCredentials: true,
    })
      .then(({ data }) => {
        const {
          user: retrievedUser,
          token,
        } = data;
        if (typeof token !== 'undefined') {
          dhid.setToken(`Bearer ${token}`);
        }
        dispatch(loaded());
        dhid.setUser(retrievedUser);
        Sentry.configureScope((scope) => scope.setUser(retrievedUser));
        return dispatch(userLoaded(retrievedUser));
      })
      .catch((error) => {
        dispatch(loadingError(error));
      });
  };
}

export function accountLogout(reason, auto) {
  return async (dispatch) => {
    dispatch(loading());

    // Fake a insta-resolving promise if we're already logging out
    const promise = auto
      ? new Promise((r) => r())
      : dhid.request(`${dhid.getBaseUrl().replace(/\/+$/, '')}/logout`, {
        method: 'post',
        data: {
          token: dhid.getToken(),
          csrfToken: await getCsrfToken(),
        },
        withCredentials: true,
      });

    return promise
      .then(() => {
        dispatch(loaded(undefined));
        dhid.setUser(undefined);
        dhid.setToken(undefined);
        Sentry.configureScope((scope) => scope.setUser(null));
        return dispatch(logout(reason));
      })
      .catch((error) => {
        dispatch(loadingError(error));
      });
  };
}

export function checkClient(domain) {
  if (domain === 'null') {
    // If we are on the local domain, just approve it right away
    return (dispatch) => {
      dispatch(authorizedClient());
    };
  }

  return (dispatch) => {
    dispatch(loading());
    dhid.request(`1/identity/client/${domain}`)
      .then(({ data }) => {
        if (data.id !== undefined) {
          dispatch(authorizedClient());
        }
        dispatch(loadedClient(data));
      })
      .catch(() => {
        dispatch(loadingClientError(undefined));
      });
  };
}

export function authorizeClient() {
  return (dispatch) => {
    dispatch(authorizedClient());
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case AUTH.SUCCESS: {
      return state.set('loading', false).set('error', undefined);
    }
    case AUTH.AUTHORIZATION_SUCCESS: {
      return state
        .set('authorization', action.authorization)
        .set('loading', false)
        .set('error', undefined);
    }
    case AUTH.CLIENT_SUCCESS: {
      return state
        .set('client', action.client)
        .set('loading', false)
        .set('error', undefined);
    }
    case AUTH.CLIENT_AUTHORIZED: {
      return state
        .set('clientAuthorized', true)
        .set('clientLoaded', true);
    }
    case AUTH.LOADING: {
      return state.set('error', undefined).set('loading', true);
    }
    case AUTH.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case AUTH.CLIENT_ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error))
        .set('clientAuthorized', false)
        .set('clientLoaded', true);
    }
    case AUTH.LOGOUT: {
      return state.set('error', action.reason);
    }
    default: {
      return state;
    }
  }
}
