// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-App-Watch-components-Stream-stream__stream--1FuHV{flex:1;min-height:100px;display:flex;flex-direction:column;background:#191919;color:#888;align-items:center;justify-content:center}.src-routes-App-Watch-components-Stream-stream__stream--1FuHV iframe{width:100%;flex:1}.src-routes-App-Watch-components-Stream-stream__iframeContainer--3HFJ_{display:flex;flex-direction:column;flex:1 1;height:100%;width:100%;overflow:auto}\n", ""]);
// Exports
exports.locals = {
	"stream": "src-routes-App-Watch-components-Stream-stream__stream--1FuHV",
	"iframeContainer": "src-routes-App-Watch-components-Stream-stream__iframeContainer--3HFJ_"
};
module.exports = exports;
