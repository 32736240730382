import PropTypes from 'prop-types';
import React from 'react';

import dhid from '../../../dhid';
// import discordLogo from './Discord-Logo+Wordmark-White.png';
import eslLogo from './esl.png';
import appleLogo from './apple.png';
import googleLogo from './google.png';

import styles from '../account.scss';

const loginWith = (provider) => () => {
  // We experienced a issue with the URL and double slashes. (.com//)
  // This causes a 404, meaning the whole login process fails.
  // Make sure the provided URL doesn't end with '//'
  const baseUrl = dhid.getBaseUrl().replace(/\/+$/, '');
  window.location.href = `${baseUrl}/login/${provider}?return_path=${window.location.pathname}`;
};

const propTypes = {
  loading: PropTypes.bool,
};

const Social = ({ loading }) => (
  <>
    <div className={styles.socialLogin}>
      <button
        className={`${styles.socialButton} ${styles.google}`}
        onClick={loginWith('google')}
        disabled={loading || !dhid}
        type="button"
      >
        <img src={googleLogo} height="16" alt="Google" />
        Continue with Google
      </button>
      <button
        className={`${styles.socialButton} ${styles.apple}`}
        onClick={loginWith('apple')}
        disabled={loading || !dhid}
        type="button"
      >
        <img src={appleLogo} height="24" style={{ width: 'auto' }} alt="Apple" />
        Continue with Apple
      </button>
      <button
        className={`${styles.socialButton} ${styles.esl}`}
        onClick={loginWith('esl')}
        disabled={loading || !dhid}
        type="button"
      >
        <img src={eslLogo} height="21" style={{ width: 'auto' }} alt="ESL" />
        Continue with ESL ID
      </button>
    </div>
  </>
);

Social.propTypes = propTypes;
export default Social;
