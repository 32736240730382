import * as Sentry from '@sentry/browser';
import PropTypes from 'prop-types';
import React from 'react';

import { isMobile } from '../../mobile/helpers';
import Button from '../../routes/App/components/Button';
import styles from './raven-error-boundary.scss';

const propTypes = {
  children: PropTypes.node,
  lightMode: PropTypes.bool,
};

class RavenErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, show: false };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (error?.skipSentry) {
      return;
    }

    if (isMobile() && window.Sentry) {
      window.Sentry.captureException(error, { extra: errorInfo });
    } else {
      Sentry.captureException(error, { extra: errorInfo });
    }
  }

  render() {
    if (this.state.error) {
      const s = (isMobile() && window.Sentry) ? window.Sentry : Sentry;

      const lastEventId = s && s.getCurrentHub()
        && s.getCurrentHub().getClient()
        && s.getCurrentHub().lastEventId();

      // render fallback UI
      return (
        <div className={this.props.lightMode ? styles.lightContainer : styles.darkContainer}>
          <div className={styles.error} onClick={() => this.setState({ show: true })} role="button" tabIndex={0}>
            <div className={styles.title}>
              Uh oh!
            </div>
            <div className={styles.message}>
              Something went wrong and we were unable to continue.
              { lastEventId
                && s.showReportDialog
                && (
                <span>
                  Our team has been notified but you can help us out even more by
                  filling out a report, just press the button below.
                </span>
                )}
            </div>
            { lastEventId
              && s.showReportDialog
              && (
              <Button onClick={() => s.showReportDialog({ eventId: lastEventId })}>
                Report
              </Button>
              )}
            {this.state.show && <div className={styles.dev}>{this.state.error.message}</div>}
          </div>
        </div>
      );
    }
    // when there's not an error, render children untouched
    return this.props.children;
  }
}

RavenErrorBoundary.propTypes = propTypes;

export default RavenErrorBoundary;
