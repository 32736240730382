import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import {
  ERROR,
  LOADING,
  SAVE_SUCCESS,
  SAVING,
  SUCCESS,
} from '../stateConstants';
import { postAndDispatch, requestAndDispatch } from '../helpers';

const MYDATA = defineAction(
  'MYDATA',
  [LOADING, SUCCESS, SAVING, SAVE_SUCCESS, ERROR],
  'mydata',
);

const defaultState = Map({
  loading: false,
  saving: false,
  error: undefined,
  data: undefined,
});

const saved = (data) => (
  { type: MYDATA.SAVE_SUCCESS, data }
);

const loading = () => (
  { type: MYDATA.LOADING }
);

const saving = () => (
  { type: MYDATA.SAVING }
);

const loaded = (data) => (
  { type: MYDATA.SUCCESS, data }
);

const loadingError = (error) => (
  { type: MYDATA.ERROR, error }
);

const loadDefaults = {
  onLoading: () => loading(),
  onSuccess: ({ data }) => loaded(data),
  onFailure: (error) => loadingError(error),
};
const saveDefaults = {
  ...loadDefaults,
  onLoading: () => saving(),
  onSuccess: ({ data }) => saved(data),
};


export const getDataRequests = () => requestAndDispatch({
  url: '1/identity/datarequest',
}, loadDefaults);

export const getDataRequest = (id) => requestAndDispatch({
  url: `1/identity/datarequest/${id}`,
  onSuccess: ({ data }) => loaded([data]),
}, loadDefaults);

export const exportMyData = () => postAndDispatch({
  url: '1/identity/datarequest',
  data: { type: 'export' },
}, saveDefaults);

export const deleteMyData = () => postAndDispatch({
  url: '1/identity/datarequest',
  data: { type: 'delete' },
}, saveDefaults);

export const verifyDataRequest = (id) => postAndDispatch({
  url: `1/identity/datarequest/${id}/verify`,
}, saveDefaults);

export const cancelDeleteRequest = () => postAndDispatch({
  url: '1/identity/datarequest/canceldelete',
}, saveDefaults);


export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case MYDATA.LOADING: {
      return state
        .set('error', undefined)
        .set('loading', true);
    }
    case MYDATA.SUCCESS: {
      return state
        .set('loading', false)
        .set('data', action.data)
        .set('error', undefined);
    }
    case MYDATA.SAVING: {
      return state
        .set('error', undefined)
        .set('saving', true);
    }
    case MYDATA.SAVE_SUCCESS: {
      return state
        .set('error', undefined)
        .set('data', action.data)
        .set('saving', false);
    }
    case MYDATA.ERROR: {
      return state
        .set('loading', false)
        .set('saving', false)
        .set('error', serializeError(action.error));
    }
    default: {
      return state;
    }
  }
}
