import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { createOnlyOnceCondition } from '../conditions';
import { requestAndDispatch } from '../helpers';

const DOCUMENTS = defineAction(
  'DOCUMENTS',
  [LOADING, SUCCESS, ERROR, 'REMOVED'],
  'documents',
);

const defaultState = Map({
  list: null,
  loading: false,
  error: false,
});

const loaded = (data) => (
  { type: DOCUMENTS.SUCCESS, data }
);

const loading = () => (
  { type: DOCUMENTS.LOADING }
);

const loadingError = (error) => (
  { type: DOCUMENTS.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
  onSuccess: ({ data }) => loaded(data),
};

const { condition: onlyOnce, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();

export const getDocumentsForEvent = (eventId) => requestAndDispatch({
  url: '1/crew/documents/event/'.concat(eventId),
  condition: onlyOnce,
}, defaults);

export const signDocumentsForEvent = (eventId, data) => requestAndDispatch({
  method: 'POST',
  data,
  url: '1/crew/documents/event/'.concat(eventId),
}, defaults);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case DOCUMENTS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', false)
        // This part camelcases the incoming data, but excludes any pattern matching a UUID
        .set('list', (state.get('list') || Map()).mergeDeep(action.data));
    }
    case DOCUMENTS.LOADING: {
      return state
        .set('error', false) // Clear the previous error
        .set('loading', true);
    }
    case DOCUMENTS.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
