import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';

import dhid from '../dhid';
import { ERROR, LOADING, SUCCESS } from './stateConstants';

const QUESTION = defineAction(
  'QUESTION',
  [
    LOADING,
    SUCCESS,
    ERROR,
    'SUBMITTED',
    'SUBMITTING',
    'SUBMIT_ERROR',
    'CLAIMED',
    'CLAIM_ERROR',
    'PLACEMENT',
  ],
  'question',
);

const defaultState = Map({
  data: undefined,
  loading: false,
  error: undefined,
  submitting: false,
  submit: undefined,
  submitError: undefined,
  awaitingClaim: [],
  claimed: false,
  placement: undefined,
});

const loaded = (question) => (
  { type: QUESTION.SUCCESS, question }
);

const placement = (data) => (
  { type: QUESTION.PLACEMENT, data }
);

const loading = (clear) => (
  { type: QUESTION.LOADING, clear }
);

const error = (err) => (
  { type: QUESTION.ERROR, err }
);

export const submitError = (err) => (
  { type: QUESTION.SUBMIT_ERROR, err }
);

export function getQuestion(code) {
  return (dispatch) => {
    dispatch(loading());
    return dhid.get(`/1/qvp/questions/byPassword/${code}`)
      .then(({ data }) => {
        dispatch(loaded(data));
      })
      .catch(
        (err) => {
          dispatch(error(err));
        },
      );
  };
}

export function getPlacement(id) {
  return (dispatch) => {
    dispatch(loading());
    return dhid.get(`/1/qvp/leaderboards/${id}/me`)
      .then(({ data }) => {
        dispatch(placement(data));
      })
      .catch(
        (err) => {
          dispatch(error(err));
        },
      );
  };
}

export const setQuestion = (data) => (dispatch) => dispatch(loaded(data));

export function claimReplies(replies) {
  const listOfIds = replies.map((reply) => reply.id);
  return (dispatch) => {
    dispatch(loading());
    return dhid.request('/1/qvp/questions/claim', {
      method: 'post',
      data: {
        replies: listOfIds,
      },
    })
      .then(() => {
        dispatch({ type: QUESTION.CLAIMED });
        return true;
      })
      .catch(
        (err) => {
          dispatch({ type: QUESTION.CLAIM_ERROR, err });
        },
      );
  };
}

export function submitAnswer(questionId, answerId, user) {
  return (dispatch) => {
    dispatch({ type: QUESTION.SUBMITTING });
    return dhid.request(`/1/qvp/questions/${questionId}/reply/${user ? 'dhid' : 'anon'}`, {
      method: 'post',
      data: {
        answer_id: answerId,
      },
    })
      .then(({ data }) => {
        dispatch({
          type: QUESTION.SUBMITTED,
          data: user ? undefined : data,
        });
      })
      .catch((err) => {
        dispatch({ type: QUESTION.SUBMIT_ERROR, err });
      });
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case QUESTION.SUCCESS: {
      return state
        .set('loading', false)
        .set('data', fromJS(action.question))
        .set('error', undefined)
        .set('submitted', undefined);
    }
    case QUESTION.PLACEMENT: {
      return state
        .set('loading', false)
        .set('placement', action.data)
        .set('error', undefined);
    }
    case QUESTION.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined)
        .set('claimed', false);
    }
    case QUESTION.ERROR: {
      return state
        .set('loading', false)
        .set('placement', undefined)
        .set('error', action.err);
    }
    case QUESTION.SUBMITTING: {
      return state
        .set('submitting', true)
        .set('submitError', undefined);
    }
    case QUESTION.SUBMIT_ERROR: {
      return state
        .set('submitting', false)
        .set('submitError', action.err);
    }
    case QUESTION.SUBMITTED: {
      const awaitingClaim = state.get('awaitingClaim');
      const newAwaitingClaim = action.data ? awaitingClaim.concat([action.data]) : [];
      return state
        .set('submitted', true)
        .set('loading', false)
        .set('error', undefined)
        .set('awaitingClaim', newAwaitingClaim);
    }
    case QUESTION.CLAIMED: {
      return state
        .set('submitted', undefined)
        .set('loading', false)
        .set('error', undefined)
        .set('awaitingClaim', [])
        .set('claimed', true);
    }
    case QUESTION.CLAIM_ERROR: {
      return state
        .set('loading', false)
        .set('error', action.err)
        .set('awaitingClaim', [])
        .set('claimed', false);
    }
    default: {
      return state;
    }
  }
}
