import React from 'react';

import MetaTags from '../../../components/MetaTags';
import dhLogo from '../../../assets/logos/onBlack@2x.png';
import styles from './cookies.scss';

export default function Cookies() {
  if (window.UC_UI_IS_RENDERED) {
    window.location.reload();
  }

  return (
    <>
      <MetaTags
        title="Cookies"
        titleSuffix="DreamHack"
        description="Control your cookie settings for DreamHack websites and services"
      />
      <div className={styles.container}>
        <div className={styles.header}>
          <a href="https://www.dreamhack.com">
            <img src={dhLogo} alt="DreamHack" width="200" height="17" />
          </a>
        </div>
        <div className={styles.content}>
          <div className={styles.explanation}>
            <h1>Cookie settings</h1>

            <p>
              Below you can find Details on our Cookies, such as what data is processed, for which purpose and on what legal basis. Please select the ones to which you agree that we may use them. You can at any time withdraw your consent or - if applicable - object to a processing based on a legitimate interest by deselecting an active button. For details on the deactivation of Essential Cookies please contact us at <a href="mailto:privacy@eslgaming.com">privacy@eslgaming.com</a>.<br />
            </p>

          </div>
          <div className={styles.control}>
            <div
              className="uc-embed"
              uc-data="all"
              uc-embedding-type="category"
              uc-show-toggle="true"
              uc-styling="true"
            />
          </div>
        </div>
      </div>
    </>
  );
}
