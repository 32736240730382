// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-Account-Authorize-authorize__scopeList--3SP0G{margin-top:0;margin-bottom:15px}.src-routes-Account-Authorize-authorize__scopeList--3SP0G li{margin-top:5px}.src-routes-Account-Authorize-authorize__scopeList--3SP0G li:first-child{margin-top:0}\n", ""]);
// Exports
exports.locals = {
	"scopeList": "src-routes-Account-Authorize-authorize__scopeList--3SP0G"
};
module.exports = exports;
