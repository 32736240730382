// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-App-components-BackgroundImage-background-image__general--mOaHH{background-color:#272729;background-blend-mode:multiply;transition:background-color 0.25s}.src-routes-App-components-BackgroundImage-background-image__bg--PAtU5{background-size:cover;background-position:center center;background-color:transparent}\n", ""]);
// Exports
exports.locals = {
	"general": "src-routes-App-components-BackgroundImage-background-image__general--mOaHH",
	"bg": "src-routes-App-components-BackgroundImage-background-image__bg--PAtU5"
};
module.exports = exports;
