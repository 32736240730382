// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-components-Spinner-Spinner__folding--3AfHk{margin:20px auto;width:40px;height:40px;position:relative;transform:rotateZ(45deg)}.src-components-Spinner-Spinner__cube--PUvUv{float:left;width:50%;height:50%;position:relative;transform:scale(1.1)}.src-components-Spinner-Spinner__cube--PUvUv:before{content:'';position:absolute;top:0;left:0;width:100%;height:100%;background-color:#f60;animation:src-components-Spinner-Spinner__spinner-folding-cube-angle--1Kf7s 2.4s infinite linear both;transform-origin:100% 100%}.src-components-Spinner-Spinner__cube--PUvUv.src-components-Spinner-Spinner__second--2nz-U{transform:scale(1.1) rotateZ(90deg)}.src-components-Spinner-Spinner__cube--PUvUv.src-components-Spinner-Spinner__second--2nz-U:before{animation-delay:0.3s}.src-components-Spinner-Spinner__cube--PUvUv.src-components-Spinner-Spinner__third--3pFDt{transform:scale(1.1) rotateZ(180deg)}.src-components-Spinner-Spinner__cube--PUvUv.src-components-Spinner-Spinner__third--3pFDt:before{animation-delay:0.6s}.src-components-Spinner-Spinner__cube--PUvUv.src-components-Spinner-Spinner__fourth--3-i5S{transform:scale(1.1) rotateZ(270deg)}.src-components-Spinner-Spinner__cube--PUvUv.src-components-Spinner-Spinner__fourth--3-i5S:before{animation-delay:0.9s}@keyframes src-components-Spinner-Spinner__spinner-folding-cube-angle--1Kf7s{0%,10%{transform:perspective(140px) rotateX(-180deg);opacity:0}25%,75%{transform:perspective(140px) rotateX(0deg);opacity:1}90%,100%{transform:perspective(140px) rotateY(180deg);opacity:0}}\n", ""]);
// Exports
exports.locals = {
	"folding": "src-components-Spinner-Spinner__folding--3AfHk",
	"cube": "src-components-Spinner-Spinner__cube--PUvUv",
	"spinner-folding-cube-angle": "src-components-Spinner-Spinner__spinner-folding-cube-angle--1Kf7s",
	"second": "src-components-Spinner-Spinner__second--2nz-U",
	"third": "src-components-Spinner-Spinner__third--3pFDt",
	"fourth": "src-components-Spinner-Spinner__fourth--3-i5S"
};
module.exports = exports;
