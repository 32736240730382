import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import UserContext from './UserContext';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function withUser(WrappedComponent) {
  const propTypes = {
    user: PropTypes.shape({
      id: PropTypes.string,
    }),
    match: PropTypes.shape({
      params: PropTypes.shape({}),
    }),
    error: PropTypes.oneOfType([
      PropTypes.shape({
        message: PropTypes.string,
      }),
      PropTypes.bool,
    ]),
  };

  const childContextTypes = {
    user: PropTypes.shape({}),
  };

  class WithUser extends React.Component {
    getChildContext() {
      return {
        user: {
          ...this.props.user,
          hasPermission: this.hasPermission(),
          hasRelation: this.hasRelation(),
          hasRole: this.hasRole(),
        },
      };
    }

    hasPermission() {
      const { access } = this.props.user || {};
      return (permission, context) => {
        const parameters = access && access.permissions && access.permissions[permission];

        if (Array.isArray(parameters) && parameters.length === 0) {
          // no parametes, full access
          return true;
        }

        if (typeof context !== 'object') {
          // no context was provided, access denied
          console.warn('Permission check is missing context', permission);
          return false;
        }

        const result = parameters
          && parameters.map((block) => Object.keys(block).reduce((acc, key) => {
            let r = false;
            switch (key) {
              case 'from_time':
                // TODO:
                break;
              case 'to_time':
                // TODO:
                break;
              default:
                r = block[key] === context[key];
                break;
            }

            return acc && r;
          }, true));

        return result && result.find((r) => r);
      };
    }

    hasRelation() {
      const { access } = this.props.user || {};
      return (group_id, relation) => access
        && access.relations
        && typeof access.relations[relation] !== 'undefined'
        && access.relations[relation].indexOf(group_id) !== -1;
    }

    hasRole() {
      const { access } = this.props.user || {};
      return (role) => access && access.roles && typeof access.roles[role] !== 'undefined';
    }

    render() {
      const {
        user, error, ...passThroughProps
      } = this.props;

      const value = {
        user: {
          ...this.props.user,
          hasPermission: this.hasPermission(),
          hasRelation: this.hasRelation(),
          hasRole: this.hasRole(),
        },
      };

      return (
        <UserContext.Provider value={value}>
          <WrappedComponent {...passThroughProps} user={user} />
        </UserContext.Provider>
      );
    }
  }

  WithUser.displayName = `withUser(${getDisplayName(WrappedComponent)})`;
  WithUser.propTypes = propTypes;
  WithUser.childContextTypes = childContextTypes;

  function mapStateToProps(state) {
    return {
      user: state.getIn(['user', 'data']),
      forceRedirect: state.getIn(['user', 'forceRedirect']),
      error: state.getIn(['user', 'error']),
    };
  }
  return connect(mapStateToProps)(WithUser);
}

export default withUser;
