import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { createOnlyOnceCondition } from '../conditions';
import { postAndDispatch, requestAndDispatch } from '../helpers';

const CREW_CALENDAR = defineAction(
  'CREW_CALENDAR',
  [LOADING, SUCCESS, ERROR],
  'crew_calendar',
);

const defaultState = Map({
  loading: false,
  list: null,
  error: undefined,
});

// Events
export const loaded = (data) => ({ type: CREW_CALENDAR.SUCCESS, data });

const loading = () => ({ type: CREW_CALENDAR.LOADING });

const loadingError = (error) => ({ type: CREW_CALENDAR.ERROR, error });

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
};

const { condition: onlyOnce, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();

// Actions
export const get = () => requestAndDispatch(
  {
    url: '1/crew/calendar',
    onSuccess: ({ data }) => loaded(data),
  },
  defaults,
);
export const getForEvent = (event_id) => requestAndDispatch(
  {
    url: `1/crew/calendar/event/${event_id}`,
    condition: onlyOnce,
    onSuccess: ({ data }) => loaded(data),
  },
  defaults,
);

export const saveArrival = (values) => postAndDispatch(
  {
    url: '1/crew/calendar',
    data: values,
    onSuccess: ({ data }) => loaded({ [data.id]: data }),
  },
  defaults,
);

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREW_CALENDAR.LOADING: {
      return state.set('loading', true).set('error', undefined);
    }
    case CREW_CALENDAR.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case CREW_CALENDAR.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('list', (state.get('list') || Map()).mergeDeep(action.data));
    }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
