import { Provider } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { hot } from 'react-hot-loader/root';
import React from 'react';

import './styles/global.scss';
import { LocalIntlProvider } from './i18n';
import { Websocket } from './components/Websocket';
import Routes from './routes';
import store from './store';
import withTracker from './components/withTracker';

// Create a custom history object that allows for native-like stack pushing
// and popping in conjunction with AnimatedRoutes.
const history = createBrowserHistory();
history.stackPush = (route) => history.push(`${history.location.pathname}/${route}`);
history.stackPop = () => history.replace(history.location.pathname.substr(0, history.location.pathname.lastIndexOf('/')));

const App = () => (
  <Provider store={store}>
    <LocalIntlProvider>
      <Websocket>
        <Router history={history}>
          <Route component={withTracker(Routes)} />
        </Router>
      </Websocket>
    </LocalIntlProvider>
  </Provider>
);

export default hot(App);
