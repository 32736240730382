import { connect } from 'react-redux';
import { history as historyPropTypes } from 'history-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import Url from 'url';
import atob from 'atob';
import btoa from 'btoa';

import { authorizeClient, checkClient } from '../ducks/user/auth';
import { navigate, navigatePush } from '../helpers';
import dhid from '../dhid';
import withUser from './withUser';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

// atob throws exceptions, we want to null control it
const nullableAtob = (urlHash) => {
  try {
    return atob(urlHash);
  } catch (e) {
    console.log('nullable atob call (\'%s\')', urlHash);
    return null;
  }
};

// Find out the referrer domain
const getDomain = ({ match }) => {
  const rawReferrer = match.params.returnHash
    ? (nullableAtob(decodeURIComponent(match.params.returnHash)) ?? '')
    : document.referrer;

  const { hostname } = Url.parse(rawReferrer);

  return encodeURIComponent(hostname);
};


function withUserRedirect(WrappedComponent) {
  const propTypes = {
    dispatch: PropTypes.func.isRequired,
    history: PropTypes.shape(historyPropTypes),
    match: PropTypes.shape({
      url: PropTypes.string,
      params: PropTypes.shape({
        returnHash: PropTypes.string,
      }),
    }),
    clientLoading: PropTypes.bool,
    clientLoaded: PropTypes.bool,
    clientAuthorized: PropTypes.bool,
  };

  class WithUserRedirect extends React.Component {
    componentDidMount() {
      this.UNSAFE_componentWillReceiveProps(this.props);
      this.checkForReturnUrl();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      this.checkUserChanged(newProps);
    }

    checkForReturnUrl() {
      const { match, history, dispatch } = this.props;
      const domain = getDomain(this.props);

      // Check if we should store the return url
      const { hostname } = Url.parse(document.location.href);
      if (typeof match.params.returnHash === 'undefined' && domain !== hostname) {
        // Save the return url in the url (so the user can reload the page)
        if (document.referrer) {
          const hash = btoa(document.referrer); // Base64
          navigatePush(history, hash);
          dispatch(checkClient(domain));
        } else {
          const hash = btoa('-'); // Base64
          navigatePush(history, hash);
          dispatch(checkClient(domain));
        }
      } else if (typeof match.params.returnHash !== 'undefined') {
        // We already have a stored return url, make sure to check that it is valid
        dispatch(checkClient(domain));
      } else if (domain === hostname) {
        // Local domain, authorize manualy
        dispatch(authorizeClient());
      }
    }

    checkUserChanged(props) {
      const {
        user,
        history,
        clientAuthorized,
        clientLoading,
        clientLoaded,
      } = props;

      const { hostname } = Url.parse(document.location.href);
      if (user && !clientLoading) {
        const domain = getDomain(props);
        const internalDomain = domain === hostname || domain === '' || domain === null;
        const redirectUrl = this.redirectUrl(clientAuthorized || internalDomain);

        if (redirectUrl === 'dreamhackapp') {
          /* The following is special code designed to handle the login flow
           * in our native apps. Please refer to the comments in the
           * src/mobile/index.js file for more information */
          const passData = JSON.stringify({
            token: dhid.getToken(),
            user: dhid.getUser(),
          });
          window.location.href = `dreamhack://data=${encodeURIComponent(passData)}`;
          return;
        }

        console.log('domain', domain, redirectUrl, clientAuthorized, internalDomain);
        if (clientAuthorized) {
          if (internalDomain) { // Internal redirect, user history api
            navigate(history, redirectUrl);
          } else { // Redirect to external domain
            console.log('redirect', redirectUrl);
            window.location.href = redirectUrl;
          }
        } else if (clientLoaded) {
          // Fallback when client is not authorized
          console.log('fallback to /app/me');
          navigate(history, '/app/me');
        }
      }
    }

    redirectUrl(clientAuthorized) {
      const { match } = this.props;
      const fallback = '/app/me';

      if (!(match && match.params && match.params.returnHash)) {
        console.warn('redirectURL not accepted, using fallback');
        return fallback;
      }

      // Control invalid redirect url to go for fallback
      const url = nullableAtob(decodeURIComponent(match.params.returnHash)) ?? '-';

      console.log('decoded redirectURL', url);

      if (url === '-') {
        return fallback;
      }

      // always allow redirects to the app
      if (url === 'dreamhackapp') {
        return url;
      }

      if (!this.isValidDestinationURL(url) || !clientAuthorized) {
        console.warn('redirectURL not accepted, should not contain /login. using fallback');
        return fallback;
      }

      // Be careful with a possible XSS vulnerability
      // in case redirect url contains a javascript expression
      // eslint-disable-next-line no-script-url
      if (url.includes('javascript:')) {
        console.warn('redirectURL not accepted, threatens security. using fallback');
        return fallback;
      }

      // In case everything looks great, redirect the user to the provided url.
      return url;
    }

    // Ensure the destination URL is not a login page to prevent infinite redirects
    // eslint-disable-next-line class-methods-use-this
    isValidDestinationURL(url) {
      const isLoginURL = url.includes('/login');
      const isException = url.includes('/login/as');
      return !isLoginURL || isException;
    }

    render() {
      // Remove props that should not be forwared
      const { ...passThroughProps } = this.props;
      return (
        <WrappedComponent
          {...passThroughProps}
          referrer={this.redirectUrl()}
        />
      );
    }
  }

  WithUserRedirect.displayName = `withUserRedirect(${getDisplayName(WrappedComponent)})`;
  WithUserRedirect.propTypes = propTypes;

  function mapStateToProps(state) {
    return {
      client: state.getIn(['auth', 'client']),
      clientLoading: state.getIn(['auth', 'loading']),
      clientLoaded: state.getIn(['auth', 'clientLoaded']),
      clientAuthorized: state.getIn(['auth', 'clientAuthorized']),
      clientError: state.getIn(['auth', 'error']),
    };
  }
  return connect(mapStateToProps)(withUser(WithUserRedirect));
}

export default withUserRedirect;
