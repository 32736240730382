import PropTypes from 'prop-types';
import React from 'react';

import styles from './Spinner.scss';

const propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

const Spinner = function render(props) {
  const size = props.size || 40;
  const pxSize = `${size}px`;
  return (
    <div className={props.className}>
      <div className={styles.folding} style={{ width: pxSize, height: pxSize }}>
        <div className={`${styles.cube} ${styles.first}`} />
        <div className={`${styles.cube} ${styles.second}`} />
        <div className={`${styles.cube} ${styles.fourth}`} />
        <div className={`${styles.cube} ${styles.third}`} />
      </div>
    </div>
  );
};

Spinner.propTypes = propTypes;

export default Spinner;
