import { createDhid, isMobile } from './mobile/helpers';
import { formatDhidError } from './helpers'; // eslint-disable-line

if (isMobile()) {
  /* Create an instance of DHID if native */
  window.dhid = createDhid();
}

const dhid = typeof window !== 'undefined' ? window.dhid : undefined;

if (dhid !== undefined) {
  dhid.interceptors.response.use((response) => {
    if (response.status >= 200 && response.status < 300) {
      return response;
    }
    return Promise.reject(response);
  }, formatDhidError);
}

export default dhid;
