import PropTypes from 'prop-types';
import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import styles from './modal.scss';

const propTypes = {
  children: PropTypes.node,
  buttonText: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  styles: PropTypes.shape({
    content: PropTypes.object,
  }),
  className: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  message: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]),
  action: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]).isRequired,
    disabled: PropTypes.bool,
  }),
};

const Modal = (props) => {
  // Get all props except className, put that in a separate variable for use
  // with classNames to combine existing modal styles with the passthrough.
  const { className, ...passThroughProps } = props;
  return (
    <ReactModal
      className={classNames(styles.modal, className)}
      overlayClassName={styles.overlay}
      closeTimeoutMS={200}
      {...passThroughProps}
    >
      {props.children}
      { props.action
        && (
        <button
          className={styles.action}
          onClick={props.action.onClick}
          type="button"
          disabled={props.action.disabled}
        >
          {props.action.text}
        </button>
        )}
      { props.buttonText
        && (
        <button
          className={styles.button}
          onClick={() => props.onRequestClose()}
          type="button"
        >
          {props.buttonText}
        </button>
        )}
    </ReactModal>
  );
};

Modal.propTypes = propTypes;

export const BottomModal = (props) => (
  <Modal
    className={classNames(
      styles.bottom,
    )}
    {...props}
  />
);

export const FloatingModal = (props) => (
  <Modal
    className={styles.floating}
    {...props}
  />
);

export const PromptModal = (props) => (
  <FloatingModal
    {...props}
  >
    <div className={styles.prompt}>
      <div className={styles.title}>
        {props.title}
      </div>
      <div className={styles.message}>
        {props.message}
      </div>
    </div>
  </FloatingModal>
);
PromptModal.propTypes = propTypes;

export default Modal;
