// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-App-components-Subheader-subheader__subheader--1Y13L{background:#050505;color:#818384;display:flex;flex-shrink:0;align-items:center;padding:12px 16px;font-size:0.9em;font-weight:bold;height:37px;text-transform:uppercase;display:flex}.src-routes-App-components-Subheader-subheader__subheader--1Y13L button{float:right}.src-routes-App-components-Subheader-subheader__subheader--1Y13L span{flex:1}.src-routes-App-components-Subheader-subheader__subheader--1Y13L .src-routes-App-components-Subheader-subheader__buttons--2gYkt{text-align:right;flex:1}.src-routes-App-components-Subheader-subheader__borderBottom--dWduu{border-bottom:0.5px solid #272729}.src-routes-App-components-Subheader-subheader__big--30skZ{margin-top:40px;border-bottom:2px solid #818384}\n", ""]);
// Exports
exports.locals = {
	"subheader": "src-routes-App-components-Subheader-subheader__subheader--1Y13L",
	"buttons": "src-routes-App-components-Subheader-subheader__buttons--2gYkt",
	"borderBottom": "src-routes-App-components-Subheader-subheader__borderBottom--dWduu",
	"big": "src-routes-App-components-Subheader-subheader__big--30skZ"
};
module.exports = exports;
