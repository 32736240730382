import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import logo from '../../../assets/logos/onBlack@2x.png';
import styles from '../account.scss';

const propTypes = {
  children: PropTypes.node,
  showHero: PropTypes.bool,
  fluid: PropTypes.bool,
};

const AuthBox = ({
  children,
  showHero,
  fluid,
}) => (
  <div className={styles.body}>
    <div className={styles.tint} />
    <div className={styles.container}>
      <div className={classNames(
        styles.content,
        { [`${styles.fixed}`]: !fluid }, /* if fluid is not set, set fixed class to content */
      )}
      >
        { children }
      </div>
      { showHero
        && (
          <div className={styles.hero}>
            <img
              className={styles.logo}
              src={logo}
              alt="DreamHack"
            />
          </div>
        )}
    </div>
  </div>
);

const defaultProps = {
  showHero: true,
  fluid: false,
};

AuthBox.propTypes = propTypes;
AuthBox.defaultProps = defaultProps;

export default AuthBox;
