import { Map } from 'immutable';
import { defineAction } from 'redux-define';

import { ERROR, LOADING, SUCCESS } from './stateConstants';
import { createOnlyOnceCondition } from './conditions';
import { requestAndDispatch } from './helpers';

const MAPS = defineAction(
  'MAPS',
  [LOADING, SUCCESS, ERROR],
  'maps',
);

const defaultState = Map({
  list: Map(),
  loading: false,
  error: false,
});

const loaded = (maps) => (
  { type: MAPS.SUCCESS, maps }
);

const loading = () => (
  { type: MAPS.LOADING }
);

const loadingError = () => (
  { type: MAPS.ERROR }
);

const { condition: onlyOnce } = createOnlyOnceCondition();
const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
  onSuccess: ({ data }) => loaded(data.reduce((acc, row) => ({ ...acc, [row.id]: row }), {})),
};

export const getMaps = () => requestAndDispatch({
  url: '1/files/maps/published',
  condition: onlyOnce,
}, defaults);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case MAPS.SUCCESS: {
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('list', state.get('list').mergeDeep(action.maps));
    }
    case MAPS.LOADING: {
      return state
        .set('loading', true);
    }
    case MAPS.ERROR: {
      return state
        .set('loading', false)
        .set('error', true);
    }
    default: {
      return state;
    }
  }
}
