// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-Press-loader__loading--3qMSi{display:flex;align-content:center;align-items:center}.src-routes-Press-loader__loading--3qMSi>div{width:100%}.src-routes-Press-loader__loading--3qMSi .src-routes-Press-loader__error--2pa00{color:#fff;margin-left:auto;margin-right:auto;width:auto;padding:20px;background:#f60;min-with:50%;max-width:90%;text-align:center}.src-routes-Press-loader__loading--3qMSi .src-routes-Press-loader__error--2pa00>pre{margin:0;margin-top:10px;overflow:auto;background:#eee;color:#000;padding:10px;text-align:left}\n", ""]);
// Exports
exports.locals = {
	"loading": "src-routes-Press-loader__loading--3qMSi",
	"error": "src-routes-Press-loader__error--2pa00"
};
module.exports = exports;
