import { Map } from 'immutable';
import { defineAction } from 'redux-define';

import { ERROR, LOADING, SUCCESS } from './stateConstants';
import { requestAndDispatch } from './helpers';

const MYORDERS = defineAction(
  'MYORDERS',
  [LOADING, SUCCESS, ERROR],
  'myorders',
);

const defaultState = Map({
  orders: [],
  loading: false,
  error: false,
});

const loaded = (data) => (
  { type: MYORDERS.SUCCESS, data }
);

const loading = () => (
  { type: MYORDERS.LOADING }
);

const loadingError = (error) => (
  { type: MYORDERS.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
  onSuccess: (data) => loaded(data),
};

export const getMyOrdersForEvent = (eventId) => requestAndDispatch({
  url: `1/content/event/${eventId}/orders/me`,
}, defaults);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case MYORDERS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', false)
        .set('orders', action.data.data);
    }
    case MYORDERS.LOADING: {
      return state
        .set('loading', true);
    }
    case MYORDERS.ERROR: {
      return state
        .set('loading', false)
        .set('error', action.error);
    }
    default: {
      return state;
    }
  }
}
