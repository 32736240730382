// Blocks all requests that already have been done
export const createOnlyOnceCondition = () => {
  const alreadyFetched = [];
  return {
    clear: () => {
      alreadyFetched.length = 0;
    },
    condition: (params) => {
      if (Array.isArray(params)) {
        const notFetched = params.filter((item) => alreadyFetched.indexOf(item) === -1);
        Array.prototype.push.apply(alreadyFetched, notFetched);
        // return only the items that we dont already have fetched
        return () => (notFetched.length ? notFetched : null);
      }

      if (params === undefined || params === null) {
        return () => null; // skip
      }

      const paramsAsString = JSON.stringify(params);
      if (alreadyFetched.indexOf(paramsAsString) !== -1) {
        return () => null; // skip
      }

      alreadyFetched.push(paramsAsString);
      return () => params; // return all params
    },
  };
};

// Blocks requests that are newer than ageLimit in seconds
export const createAgeCondition = (ageLimit) => {
  const alreadyFetched = {};
  return {
    clear: () => {
      Object.keys(alreadyFetched).forEach((key) => delete alreadyFetched[key]);
    },
    condition: (params) => {
      const timestamp = +new Date();

      // Remove to old timestamps
      Object.keys(alreadyFetched).forEach((key) => {
        alreadyFetched[key] = alreadyFetched[key]
          .filter((age) => age + (ageLimit * 1000) > timestamp);

        if (alreadyFetched[key].length === 0) {
          delete alreadyFetched[key];
        }
      });

      if (Array.isArray(params)) {
        const notFetched = params.filter((item) => {
          const itemAsString = JSON.stringify(item);

          if (alreadyFetched[itemAsString]) {
            alreadyFetched[itemAsString].push(timestamp);
            return true;
          }
          return false;
        });

        // return only the items that we dont already have fetched
        return () => (notFetched.length ? notFetched : null);
      }

      if (params === undefined || params === null) {
        return () => null; // skip
      }

      const paramsAsString = JSON.stringify(params);
      if (alreadyFetched[paramsAsString]) {
        return () => null; // skip
      }

      alreadyFetched[paramsAsString] = alreadyFetched[paramsAsString] || [];
      alreadyFetched[paramsAsString].push(timestamp);
      return () => params; // return all params
    },
  };
};

export default null;
