import { Map, Set } from 'immutable';
import { defineAction } from 'redux-define';
import moment from 'moment';

import { ERROR, LOADING, SUCCESS } from './stateConstants';
import dhid from '../dhid';

const EVENT = defineAction(
  'EVENT',
  [
    LOADING,
    SUCCESS,
    ERROR,
    'CLEAR_ERROR',
    'ACTIVITY_ADD',
    'ACTIVITY_REMOVE',
    'TICKETS_SUCCESS',
    'TICKETTYPES_SUCCESS',
    'TAG_ADD',
    'TAG_REMOVE',
    'SET_ACTIVE_EVENT',
    'CLEAR_ACTIVE_EVENT',
    'READ_MESSAGES',
  ],
  'event',
);

const defaultState = Map({
  data: undefined,
  activities: Set(),
  loading: false,
  error: undefined,
  tickets: [],
  notifications: [],
  filterTags: Set(),
  readMessages: Set(),
});

const loaded = (events) => (
  { type: EVENT.SUCCESS, events }
);

const loading = (clear) => (
  { type: EVENT.LOADING, clear }
);

const error = (err) => (
  { type: EVENT.ERROR, err }
);

const activityAdd = (id) => (
  { type: EVENT.ACTIVITY_ADD, id }
);

const activityRemove = (id) => (
  { type: EVENT.ACTIVITY_REMOVE, id }
);

const ticketLoaded = (tickets) => (
  { type: EVENT.TICKETS_SUCCESS, tickets }
);

const ticketTypesLoaded = (tickets) => (
  { type: EVENT.TICKETTYPES_SUCCESS, tickets }
);

export const addToFilter = (id) => (
  { type: EVENT.TAG_ADD, id }
);

export const removeFromFilter = (id) => (
  { type: EVENT.TAG_REMOVE, id }
);

export const setActiveEvent = (shortcode) => (
  { type: EVENT.SET_ACTIVE_EVENT, shortcode }
);

export const clearActiveEvent = () => (
  { type: EVENT.CLEAR_ACTIVE_EVENT }
);

export const readMessages = (messages) => (
  { type: EVENT.READ_MESSAGES, messages }
);

export const clearError = () => (
  { type: EVENT.CLEAR_ERROR }
);

export function getEvent(shortcode, clear = false) {
  return (dispatch) => {
    dispatch(loading(clear));

    return dhid.get(`1/content/event/${shortcode}`)
      .then(({ data }) => {
        // We manipulate the activities a bit here (sorting and time representations)
        // in order to avoid costly recalculations in component renders.
        const sortedActivities = (data && data.activities && data.activities
          .sort((a, b) => (
            moment(a.time).diff(moment(b.time))
          ))) || [];
        // Same with messages
        const sortedMessages = (data && data.ticker_messages && data.ticker_messages
          .sort((a, b) => (
            moment(b.created_at).diff(moment(a.created_at))
          ))) || [];

        const momentActivities = sortedActivities && sortedActivities.map((activity) => (
          {
            ...activity,
            momentDay: moment(activity.time).format('YYYY-MM-DD'),
          }
        ));
        dispatch(setActiveEvent(data && data.shortcode));
        dispatch(loaded({
          ...data,
          activities: momentActivities,
          ticker_messages: sortedMessages,
        }));
      })
      .catch(
        (err) => {
          dispatch(error(err));
        },
      );
  };
}

export function getTickets(eventId) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.get(`1/content/event/${eventId}/tickets`)
      .then(({ data }) => {
        dispatch(ticketLoaded(data));
      })
      .catch(
        (err) => {
          dispatch(error(err));
        },
      );
  };
}

export function getTicketTypes(eventId) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.get(`1/content/event/${eventId}/ticket_types`)
      .then(({ data }) => {
        dispatch(ticketTypesLoaded(data));
      })
      .catch(
        (err) => {
          dispatch(error(err));
        },
      );
  };
}

export function getTicketAsAdmin(eventId, ticketId) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.get(`1/content/event/${eventId}/tickets/${ticketId}`)
      .then(({ data }) => {
        dispatch(ticketLoaded([data]));
      })
      .catch(
        (err) => {
          dispatch(error(err));
        },
      );
  };
}


export function postClaimFreeTicket(eventId, ticketType, referralId) {
  return (dispatch) => {
    dispatch(clearError());
    return dhid.post(`1/content/event/${eventId}/tickets/claim`, { ticket_type: ticketType, referral: referralId })
      .then(({ data }) => {
        dispatch(ticketLoaded(data));
        return data;
      })
      .catch(
        (err) => {
          dispatch(error(err));
          throw err;
        },
      );
  };
}

export function addActivity(id) {
  return (dispatch) => (
    dispatch(activityAdd(id))
  );
}

export function removeActivity(id) {
  return (dispatch) => (
    dispatch(activityRemove(id))
  );
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case EVENT.SUCCESS: {
      return state
        .set('loading', false)
        .set('data', action.events)
        .set('error', undefined);
    }
    case EVENT.LOADING: {
      const existingData = state.get('data');
      const existingTags = state.get('filterTags');
      return state
        .set('error', undefined)
        .set('loading', true)
        .set('data', action.clear ? defaultState.get('data') : existingData)
        .set('filterTags', action.clear ? defaultState.get('filterTags') : existingTags);
    }
    case EVENT.ERROR: {
      return state
        .set('loading', false)
        .set('error', action.err);
    }
    case EVENT.CLEAR_ERROR: {
      return state
        .set('error', undefined);
    }
    case EVENT.ACTIVITY_ADD: {
      const activities = state.get('activities');
      return state
        .set('activities', activities.add(action.id));
    }
    case EVENT.ACTIVITY_REMOVE: {
      const activities = state.get('activities');
      return state
        .set('activities', activities.delete(action.id));
    }
    case EVENT.TAG_ADD: {
      const tags = state.get('filterTags');
      return state
        .set('filterTags', tags.add(action.id));
    }
    case EVENT.TAG_REMOVE: {
      const tags = state.get('filterTags');
      return state
        .set('filterTags', tags.delete(action.id));
    }
    case EVENT.TICKETS_SUCCESS: {
      return state
        .set('tickets', action.tickets)
        .set('loading', false);
    }
    case EVENT.TICKETTYPES_SUCCESS: {
      return state
        .set('ticket_types', action.tickets)
        .set('loading', false);
    }
    case EVENT.SET_ACTIVE_EVENT: {
      return state
        .set('activeEvent', action.shortcode);
    }
    case EVENT.CLEAR_ACTIVE_EVENT: {
      return state
        .set('activeEvent', undefined);
    }
    case EVENT.READ_MESSAGES: {
      const oldReadMessages = state.get('readMessages');
      const newReadMessages = new Set([...oldReadMessages, ...action.messages]);
      return state
        .set('readMessages', newReadMessages);
    }
    default: {
      return state;
    }
  }
}
