import en from '../../i18n/en.json';
import es from '../../i18n/translation/es.json';
import sv from '../../i18n/translation/sv.json';
import fr from '../../i18n/translation/fr.json';

const localeData = {
  ...sv,
  ...en,
  ...es,
  ...fr,
};

const findLanguage = () => (
  (typeof window !== 'undefined' && window.localStorage && window.localStorage.language)
  || ((typeof navigator !== 'undefined' && ((navigator.languages && navigator.languages[0]) || navigator.language || navigator.userLanguage)) || 'en').toLowerCase().split(/[_-]+/)[0]
);

export { findLanguage, localeData };
