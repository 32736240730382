import PropTypes from 'prop-types';
import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';

import { isMobile, NativeImgCache as ImgCache } from '../../../../mobile/helpers';
import styles from './background-image.scss';

const propTypes = {
  src: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.string,
  /* eslint-disable react/forbid-prop-types */
  style: PropTypes.object,
  /* eslint-enable */
};

class BackgroundImage extends React.Component {
  constructor() {
    super();
    this.state = {
      loaded: false,
      src: undefined,
    };
  }

  componentWillUnmount() {
    if (this.image) {
      this.image.onerror = null;
      this.image.onload = null;
      this.image = null;
    }
  }

  onVisibilityChange = (isVisible) => {
    const { loaded } = this.state;
    const { src } = this.props;

    if (isVisible && !loaded && src) {
      if (isMobile() && ImgCache && ImgCache.ready) {
        ImgCache.isCached(src, (onlinePath, success) => {
          if (success) {
            ImgCache.getCachedFileURL(src, (originalPath, foundPath) => {
              this.setImage(foundPath);
            });
          } else {
            this.setImage(src);
            ImgCache.cacheFile(src);
          }
        });
      } else {
        this.setImage(src);
      }
    }
  }

  setImage = (src) => {
    this.image = new Image();
    this.image.src = src;
    this.image.onload = () => this.setLoaded(src);
    this.imageonerror = () => this.setLoaded(src);
  }

  setLoaded = (src) => {
    this.setState({
      loaded: true,
      src,
    });
  }

  render() {
    const { children } = this.props;
    const { loaded, src } = this.state;
    const style = loaded ? { backgroundImage: `url(${src})` } : {};
    const { className, style: propStyle = {}, ...restProps } = this.props;
    return (
      <VisibilitySensor
        onChange={this.onVisibilityChange}
        partialVisibility
      >
        <div
          className={classNames(
            className,
            styles.general,
            {
              [styles.bg]: loaded,
            },
          )}
          style={{
            ...style,
            ...propStyle,
          }}
          {...restProps}
        >
          {children}
        </div>
      </VisibilitySensor>
    );
  }
}

BackgroundImage.propTypes = propTypes;

export default BackgroundImage;
