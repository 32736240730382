import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

import styles from './button.scss';

const propTypes = {
  outline: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  arrow: PropTypes.bool,
  icon: PropTypes.string,
  background: PropTypes.string,
  external: PropTypes.bool,
  badge: PropTypes.number,
};

export const BarButton = (props) => (
  <button
    onClick={props.onClick}
    disabled={props.disabled}
    className={classNames(
      styles.barButton,
      { [`${styles.outline}`]: props.outline },
    )}
    type="button"
  >
    { props.icon
      && (
      <i
        className={classNames(
          styles.icon,
          `far fa-${props.icon}`,
        )}
        // style={{
          // background: props.background,
        // }}
      />
      )}
    {props.children}
    {props.badge > 0 && (
      <span className={styles.badge}>{props.badge}</span>
    )}
    { (props.arrow || props.external)
      && (
      <i className={classNames(
        styles.arrow,
        props.external ? 'far fa-external-link' : 'far fa-arrow-right',
      )}
      />
      )}
  </button>
);

BarButton.propTypes = { ...propTypes, arrow: PropTypes.bool };

export default BarButton;
