import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { createOnlyOnceCondition } from '../conditions';
import {
  postAndDispatch,
} from '../helpers';

const CREW_EVAL = defineAction(
  'CREW_EVAL',
  [LOADING, SUCCESS, ERROR],
  'crew_eval',
);

const defaultState = Map({
  loading: false,
  list: Map(),
  error: undefined,
});

// Events
export const loaded = (data) => (
  { type: CREW_EVAL.SUCCESS, data }
);

const loading = () => (
  { type: CREW_EVAL.LOADING }
);

const loadingError = (error) => (
  { type: CREW_EVAL.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
};

const { condition: onlyOnceCondition, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();

// Actions
export const getMyEvaluations = (event_id, group_id, users) => postAndDispatch({
  url: '1/crew/evaluations/search',
  data: onlyOnceCondition({
    user_id: users,
    event_id,
    group_id,
  }),
  onSuccess: ({ data }) => loaded(
    data,
  ),
}, defaults);

export const getUser = (user_id) => postAndDispatch({
  url: '1/crew/evaluations/user',
  data: onlyOnceCondition({
    user_id,
  }),
  onSuccess: ({ data }) => loaded(
    data,
  ),
}, defaults);

export const evaluate = (user_id, group_id, event_id, ratings) => postAndDispatch({
  url: '1/crew/evaluations',
  data: {
    user_id,
    group_id,
    event_id,
    data: ratings,
  },
  onSuccess: ({ data }) => loaded({
    [data.id]: data,
  }),
}, defaults);

// Reducer
export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREW_EVAL.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case CREW_EVAL.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case CREW_EVAL.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('list', state.get('list').mergeDeep(action.data));
    }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
