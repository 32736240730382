import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import dhid from '../../../dhid';
import styles from '../account.scss';
import AuthBox from '../components/AuthBox';
import MetaTags from '../../../components/MetaTags';
import Social from './Social';
import withUserRedirect from '../../../components/withUserRedirect';

const propTypes = {
  client: PropTypes.shape({
    name: PropTypes.string,
  }),
  clientLoading: PropTypes.bool,
  loading: PropTypes.bool,
  userError: PropTypes.shape({
    message: PropTypes.string,
    json: PropTypes.shape({}),
  }),
  match: PropTypes.shape({
    params: PropTypes.shape({
      returnHash: PropTypes.string,
    }),
  }),
  location: PropTypes.shape({
    search: PropTypes.string,
  }),
};

class Login extends React.PureComponent {
  render() {
    const {
      loading,
      client,
      clientLoading,
    } = this.props;

    if (!dhid && typeof window !== 'undefined') {
      return (
        <div className={styles.error}>
          The DreamHack login is currently unavailable. Please try again later.
        </div>
      );
    }

    return (
      <AuthBox>
        <MetaTags
          title="DreamHack – Login"
          titleSuffix={false}
        />
        {
          !dhid && typeof window !== 'undefined' && (
            <div className={styles.error}>
              The DreamHack login is currently unavailable. Please try again later.
            </div>
          )
        }
        <h2 className={styles.title}>
          Welcome to DreamHack
        </h2>
        <Social loading={loading} />
        <p className={styles.agreement}>
          By continuing, you agree to our{' '}
          <a href="https://dreamhack.com/policy/" target="_blank" rel="noopener noreferrer">User Agreement{' '}</a>
          and acknowledge that you understand the{' '}
          <a href="https://dreamhack.com/privacy/" target="_blank" rel="noopener noreferrer">Privacy Policy</a>.
        </p>
        {!clientLoading && client !== undefined && client
          && (
            <p className={styles.redirect}>
              <em>
                Don&#39;t worry, we&#39;ll take you back to
                {' '}
                <strong>{client.name}</strong>
                {' '}
                as soon as you&#39;re logged in.
              </em>
            </p>
          )}
      </AuthBox>
    );
  }
}

Login.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    loading: state.getIn(['user', 'loading']) || state.getIn(['auth', 'loading']),
    userError: state.getIn(['user', 'error']),
    authError: state.getIn(['auth', 'error']),
  };
}

export default withUserRedirect(connect(mapStateToProps)(Login));
