import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import Url from 'url';

import { authorize, getAuthorization } from '../../../ducks/user/auth';
import AuthBox from '../components/AuthBox';
import Button from '../components/Button';
import MetaTags from '../../../components/MetaTags';
import accountStyles from '../account.scss';
import requireUser from '../../../components/requireUser';
import styles from './authorize.scss';

const propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    name: PropTypes.string,
    email: PropTypes.string,
  }),
  authorization: PropTypes.shape({
    approved: PropTypes.bool,
    redirect: PropTypes.string,
    client: PropTypes.string,
    scopes: PropTypes.arrayOf(PropTypes.shape({
      description: PropTypes.string,
    })),
  }),
  loading: PropTypes.bool,
  authLoading: PropTypes.bool,
  error: PropTypes.shape({
    json: PropTypes.object,
    message: PropTypes.string,
  }),
};

const getQueryParam = (props, param) => {
  const result = props.location.search.match(
    new RegExp(`(\\?|&)${param}(\\[\\])?=([^&]*)`),
  );
  return result ? decodeURIComponent(result[3]) : false;
};

class Authorize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { dispatch, user } = this.props;

    if (user) {
      dispatch(getAuthorization(window.location.search));
    }
  }

  UNSAFE_componentWillReceiveProps(props) {
    const {
      dispatch,
      user,
      authLoading,
      authorization,
      error,
    } = props;

    if (user && !authLoading && !authorization && !error) {
      dispatch(getAuthorization(window.location.search));
    }
  }

  componentDidUpdate() {
    const { authorization } = this.props;

    if (authorization !== undefined
        && authorization
        && authorization.redirect !== undefined
    ) {
      window.location.href = authorization.redirect;
    }
  }

  onSubmit = () => {
    const { dispatch } = this.props;

    dispatch(authorize(window.location.search));
  };

  render() {
    const { onSubmit } = this;
    const {
      authorization,
      loading,
      error,
      user,
    } = this.props;
    const url = Url.parse(decodeURIComponent(getQueryParam(this.props, 'redirect_uri')));
    const domain = url.hostname;

    let scopes = [{
      id: 'default',
      description: 'Read your username, name and email address',
    }];

    // Append the requested scopes
    if (typeof authorization !== 'undefined' && authorization && typeof authorization.scopes !== 'undefined') {
      scopes = scopes.concat(authorization.scopes);
    }

    return (
      <AuthBox>
        <MetaTags
          title="DreamHack – Authorize"
          titleSuffix={false}
        />
        {!loading && (error)
          && (
          <div className={accountStyles.error}>
            {'We couldn\'t authorize you'}
          </div>
          )}
        {!loading && !error && authorization
          && authorization.redirect !== undefined
          && (
          <div className={accountStyles.success}>
            Approved, redirecting you back to
            {' '}
            <strong>{authorization.client}</strong>
          </div>
          )}
        <h1 className={accountStyles.title}>
          Authorization request
        </h1>
        { error
          && (
            <>
              <p>
                Bad news! We failed to verify the authorization request for the
                {' '}
                domain
                {' '}
                <strong>{domain}</strong>
                .
              </p>
              <p>
                For security reasons we
                cannot authorize the request.
              </p>
              <p>
                The error message was:
                {' '}
                <strong>{error.message}</strong>
              </p>
            </>
          )}
        {!loading && authorization
          && (
            <>
              <p>
                <strong>{authorization.client}</strong>
                {' '}
                is requesting permission to access your DreamHack account (
                {user && user.name}
                ).
              </p>
              <h2 className={accountStyles.subtitle}>
                This application will be able to:
              </h2>
              <ul className={styles.scopeList}>
                {scopes && scopes.map(
                  (scope) => (<li key={scope.id}>{scope.description}</li>),
                )}
              </ul>
            </>
          )}
        {loading && !authorization
          && (
            <>
              Please wait a minute while we try to authorize you.
            </>
          )}
        { (error)
          && (
          <Button
            onClick={() => onSubmit()}
            disabled={
              loading
              || !authorization
              || error
              || (authorization && authorization.redirect !== undefined)
            }
          >
            Authorize
          </Button>
          )}
      </AuthBox>
    );
  }
}

// Authorize.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    account: state.getIn(['user', 'data']),
    loading: state.getIn(['user', 'loading']) || state.getIn(['auth', 'loading']),
    error: state.getIn(['auth', 'error']),
    authLoading: state.getIn(['auth', 'loading']),
    authorization: state.getIn(['auth', 'authorization']),
  };
}

Authorize.propTypes = propTypes;

export default requireUser(connect(mapStateToProps)(Authorize));
