import { Map } from 'immutable';
import { defineAction } from 'redux-define';

import { ERROR, LOADING, SUCCESS } from './stateConstants';
import { createOnlyOnceCondition } from './conditions';
import { putAndDispatch, requestAndDispatch } from './helpers';

const LIST_SPLASH_EVENTS = defineAction(
  'LIST_SPLASH_EVENT',
  [LOADING, SUCCESS, ERROR, 'UPDATE'],
  'events',
);

const defaultState = Map({
  events: [],
  loading: false,
  error: false,
  loaded: false,
});

const listSplashEvents = (events) => (
  { type: LIST_SPLASH_EVENTS.SUCCESS, events }
);

const loading = () => (
  { type: LIST_SPLASH_EVENTS.LOADING }
);

const loadingError = () => (
  { type: LIST_SPLASH_EVENTS.ERROR }
);

const updateEvent = (events) => (
  { type: LIST_SPLASH_EVENTS.UPDATE, events }
);

const defaults = {
  onLoading: () => loading(),
  onSuccess: ({ data }) => listSplashEvents(data),
  onFailure: (error) => loadingError(error),
};
const { condition: onlyOnce } = createOnlyOnceCondition();

export const getSplashEventList = () => requestAndDispatch({
  url: '1/content/event/promotion',
}, defaults);

export const getPublicEventList = () => requestAndDispatch({
  url: '1/content/event/public',
  condition: onlyOnce,
}, defaults);

export const saveEvent = (eventId, values) => putAndDispatch(
  {
    url: `1/content/event/${eventId}`,
    data: values,
    onSuccess: ({ data }) => updateEvent({ [data.id]: data }),
  },
  defaults,
);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case LIST_SPLASH_EVENTS.SUCCESS: {
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('events', action.events)
        .set('error', false);
    }
    case LIST_SPLASH_EVENTS.UPDATE: {
      const events = state.get('events').map((event) => action.events[event.id] || event);
      return state
        .set('loading', false)
        .set('loaded', true)
        .set('events', events)
        .set('error', false);
    }
    case LIST_SPLASH_EVENTS.LOADING: {
      return state
        .set('loading', true);
    }
    case LIST_SPLASH_EVENTS.ERROR: {
      return state
        .set('loading', false)
        .set('error', true);
    }
    default: {
      return state;
    }
  }
}
