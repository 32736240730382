import { Map } from 'immutable';
import { defineAction } from 'redux-define';

import { ERROR, LOADING, SUCCESS } from './stateConstants';
import dhid from '../dhid';

const SEATMAP = defineAction(
  'SEATMAP',
  [
    LOADING,
    SUCCESS,
    ERROR,
    'EMAIL_SENT',
    'TICKET_GIFTED',
    'SEAT_CLAIMED',
  ],
  'seatmap',
);

const defaultState = Map({
  order: undefined,
  loading: false,
  error: undefined,
});

const loaded = (dataType, data) => (
  { type: SEATMAP.SUCCESS, dataType, data }
);

const loading = () => (
  { type: SEATMAP.LOADING }
);

const loadingError = (error) => (
  { type: SEATMAP.ERROR, error }
);

export function fetchOrder(eventId, orderId) {
  return (dispatch) => {
    dispatch(loading());

    return dhid.get(`/1/content/event/${eventId}/orders/${orderId}`)
      .then(({ data }) => {
        if (data && data.claimed_by && data.claimed_by === dhid.getUserID()) {
          dispatch(loaded('order', data));
          dispatch(loadingError('You have already connected this order'));
          return data;
        } if (data && data.claimed_by) {
          dispatch(loadingError('This order has already been connected'));
          return false;
        }
        dispatch(loaded('order', data));
        return data;
      })
      .catch(() => {
        dispatch(loadingError('The order could not be retrieved'));
        return false;
      });
  };
}

export function claimOrder(eventId, orderId) {
  return (dispatch) => {
    dispatch(loading());
    return dhid.post(`/1/content/event/${eventId}/orders/${orderId}/claim`)
      .then(({ data }) => {
        dispatch(loaded('claim', data));
        return true;
      })
      .catch((error) => (
        dispatch(loadingError(error))
      ));
  };
}

export function emailSeat(eventId, ticketId) {
  return (dispatch) => {
    dispatch(loading());
    return dhid.post(`/1/content/event/${eventId}/tickets/${ticketId}/email`)
      .then(({ data }) => {
        dispatch({ type: SEATMAP.EMAIL_SENT });
        return data;
      })
      .catch((error) => {
        dispatch(loadingError(error));
        return false;
      });
  };
}

export function giftTicket(eventId, ticketId, email) {
  return (dispatch) => {
    dispatch(loading());
    return dhid.post(`/1/content/event/${eventId}/tickets/gift`, {
      email,
      tickets: [ticketId],
    })
      .then(({ data }) => {
        dispatch({ type: SEATMAP.TICKET_GIFTED });
        return data;
      })
      .catch((error) => {
        dispatch(loadingError(error));
        return false;
      });
  };
}

export function claimSeat(eventId, ticketId, seatId, password = '') {
  return (dispatch) => {
    dispatch(loading());
    return dhid.post(`/1/content/event/${eventId}/tickets/${ticketId}/place/${seatId}`, {
      password,
    })
      .then(({ data }) => {
        dispatch({ type: SEATMAP.SEAT_CLAIMED });
        return data;
      })
      .catch((error) => {
        dispatch(loadingError(error));
        return false;
      });
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SEATMAP.SUCCESS: {
      return state
        .set(action.dataType, action.data)
        .set('loading', false)
        .set('error', undefined);
    }
    case SEATMAP.EMAIL_SENT: {
      return state
        .set('loading', false)
        .set('error', undefined);
    }
    case SEATMAP.TICKET_GIFTED: {
      return state
        .set('loading', false)
        .set('error', undefined);
    }
    case SEATMAP.SEAT_CLAIMED: {
      return state
        .set('loading', false)
        .set('error', undefined);
    }
    case SEATMAP.LOADING: {
      return state
        .set('error', undefined);
    }
    case SEATMAP.ERROR: {
      return state
        .set('error', action.error)
        .set('loading', undefined);
    }
    default: {
      return state;
    }
  }
}
