import Url from 'url';

import dhid from '../../dhid';
import Primus from '../../primus';

const initPrimus = () => {
  const { protocol, host } = Url.parse(dhid.getApiUrl());
  const url = `${protocol}//${host}/1/socket/connection`;
  const primus = Primus.connect(url,
    {
      reconnect: {
        max: 60000,
        min: 500,
        retries: Infinity,
      },
    });

  dhid.setPrimusInstance(primus);
  return primus;
};

const getPrimus = () => dhid.getPrimusInstance() || initPrimus();

/* eslint-disable import/prefer-default-export */
export { getPrimus };
