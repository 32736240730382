import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';

import { findLanguage, localeData } from './helpers';

const propTypes = {
  language: PropTypes.string,
  children: PropTypes.node,
};

const LocalIntlProvider = ({ language, children }) => {
  const lang = language || findLanguage();
  return (
    <IntlProvider
      locale={lang}
      key={lang}
      messages={localeData[lang]}
    >
      {children}
    </IntlProvider>
  );
};

LocalIntlProvider.propTypes = propTypes;

function mapStateToProps(state) {
  return {
    language: state.getIn(['app', 'language']),
  };
}

export default connect(mapStateToProps)(LocalIntlProvider);
