import PropTypes from 'prop-types';
import React from 'react';
import TagManager from 'react-gtm-module';

import dhid from '../dhid';

if (window && window.dhid && window.dhid.onCookieConsent) {
  const { id, email } = dhid.getUser() || {};
  const dataLayer = {
    user: {
      id,
      email,
    },
  };

  TagManager.initialize({
    gtmId: 'GTM-M583WK9',
    dataLayer,
  });
}

let prevUrl = ''; // Keep track on the last reported url
const withTracker = (WrappedComponent) => {
  const trackPage = (page) => {
    if (page !== prevUrl) {
      prevUrl = page;
      if (window && window.dataLayer) {
        TagManager.dataLayer({
          dataLayer: {
            event: 'trackPage',
            'Page URL': page,
          },
        });
      }
    }
  };

  const propTypes = {
    location: PropTypes.shape({
      pathname: PropTypes.string.isRequired,
    }).isRequired,
  };

  const HOC = (props) => {
    const page = props.location.pathname;
    trackPage(page);

    return (
      <WrappedComponent {...props} />
    );
  };

  HOC.propTypes = propTypes;

  return HOC;
};

export default withTracker;
