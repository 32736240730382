import { defineAction } from 'redux-define';
import { fromJS } from 'immutable';

const APP = defineAction(
  'APP',
  ['UPDATE'],
  'app',
);

let defaultStoredState = {};
try {
  defaultStoredState = JSON.parse(localStorage.getItem('dhcom-app'));
} catch (ex) {
  // continue regardless of error
}

const defaultState = fromJS({
  language: undefined,
  eventNotificationsEnabled: {},
  debug: false,
  forceMobileMode: localStorage.getItem('forceMobileMode'),
  seenOffers: [],
  ...defaultStoredState,
});

export const update = (state) => (
  { type: APP.UPDATE, state }
);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case APP.UPDATE: {
      const newState = state
        .mergeDeep(action.state);

      localStorage.setItem('dhcom-app', JSON.stringify(newState.toJS()));

      return newState;
    }
    default: {
      return state;
    }
  }
}
