import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';

import { AUTH } from './user/auth';
import { ERROR, LOADING, SUCCESS } from './stateConstants';
import { createAgeCondition } from './conditions';
import { postAndDispatch, requestAndDispatch } from './helpers';

const ACHIEVEMENTS = defineAction(
  'ACHIEVEMENTS',
  [LOADING, SUCCESS, ERROR, 'LOADING_ME', 'SUCCESS_ME', 'ERROR_ME', 'CLAIMING', 'CLAIMED', 'DELETED', 'CLAIM_ERROR'],
  'achievements',
);

const defaultState = Map({
  achievements: null,
  userAchievements: null,
  loading: false,
  error: undefined,
  loadingMe: false,
  errorMe: undefined,
  claiming: false,
  claimError: undefined,
  claimed: false,
});

const { condition: ageCondition, clear: clearAgeCondition } = createAgeCondition(60 /* seconds */);

export const getMyAchievements = (force = false) => requestAndDispatch({
  url: '1/qvp/achievements/me',
  onLoading: () => ({ type: ACHIEVEMENTS.LOADING_ME }),
  onSuccess: ({ data }) => ({ type: ACHIEVEMENTS.SUCCESS_ME, data }),
  onFailure: (err) => ({ type: ACHIEVEMENTS.ERROR_ME, err }),
  condition: !force && ageCondition,
});

export const claim = (id, event_id) => postAndDispatch({
  url: `1/qvp/achievements/${id}/claim`,
  data: {
    event_id,
  },
  onLoading: () => ({ type: ACHIEVEMENTS.CLAIMING }),
  onSuccess: ({ data }) => ({ type: ACHIEVEMENTS.CLAIMED, data }),
  onFailure: (err) => ({ type: ACHIEVEMENTS.CLAIM_ERROR, err }),
});

export const withdraw = (id) => requestAndDispatch({
  url: `1/qvp/achievements/${id}/claim`,
  method: 'delete',
  onLoading: () => ({ type: ACHIEVEMENTS.CLAIMING }),
  onSuccess: ({ data }) => ({ type: ACHIEVEMENTS.DELETED, data }),
  onFailure: (err) => ({ type: ACHIEVEMENTS.CLAIM_ERROR, err }),
});

export const getAchievements = (id, force = false) => requestAndDispatch({
  url: `1/qvp/achievements/public/${id}`,
  onLoading: () => ({ type: ACHIEVEMENTS.LOADING }),
  onSuccess: ({ data }) => ({ type: ACHIEVEMENTS.SUCCESS, data }),
  onFailure: (err) => ({ type: ACHIEVEMENTS.ERROR, err }),
  condition: !force && ageCondition,
});

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case ACHIEVEMENTS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('achievements', fromJS(action.data));
    }
    case ACHIEVEMENTS.LOADING: {
      return state
        .set('loading', true)
        .set('error', undefined);
    }
    case ACHIEVEMENTS.ERROR: {
      return state
        .set('loading', false)
        .set('error', true);
    }
    case ACHIEVEMENTS.SUCCESS_ME: {
      return state
        .set('loadingMe', false)
        .set('error', undefined)
        .set('userAchievements', fromJS(action.data));
    }
    case ACHIEVEMENTS.LOADING_ME: {
      return state
        .set('loadingMe', true)
        .set('error', undefined);
    }
    case ACHIEVEMENTS.ERROR_ME: {
      return state
        .set('loadingMe', false)
        .set('error', true);
    }
    case ACHIEVEMENTS.CLAIMING: {
      return state
        .set('claiming', true)
        .set('claimed', false)
        .set('claimError', undefined);
    }
    case ACHIEVEMENTS.CLAIMED: {
      return state
        .set('claiming', false)
        .set('claimed', true)
        .set('claimError', undefined)
        .set('userAchievements', state.get('userAchievements').push(fromJS(action.data)));
    }
    case ACHIEVEMENTS.DELETED: {
      return state
        .set('claiming', false)
        .set('claimed', false)
        .set('claimError', undefined)
        .set('userAchievements', state.get('userAchievements').filter((item) => !action.data.includes(item.get('id'))));
    }
    case ACHIEVEMENTS.CLAIM_ERROR: {
      return state
        .set('claiming', false)
        .set('claimError', action.err.response.data);
    }
    case AUTH.LOGOUT:
      clearAgeCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
