/* eslint-disable import/no-cycle */
// Above rule is because this imports from ./auth.js remove and fix when you read this
import { Map } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH, attemptLogin } from './auth';
import {
  ERROR,
  LOADING,
  SAVE_SUCCESS,
  SUCCESS,
} from '../stateConstants';
import { createOnlyOnceCondition } from '../conditions';
import { getCsrfToken, postAndDispatch, requestAndDispatch } from '../helpers';
import dhid from '../../dhid';

export const USER = defineAction(
  'USER',
  [LOADING, SUCCESS, SAVE_SUCCESS, ERROR, 'TICKETS_SET', 'TICKETS_ERROR', 'DATA_SUCCESS', 'POLICIES_SUCCESS', 'VERIFY_SUCCESS', 'VERIFY_ERROR'],
  'user',
);

const defaultState = Map({
  data: dhid?.getUser(),
  loading: false,
  error: undefined,
  user_data: {},
  policies: [],
  missing_fields: [],
  required_fields: [],
  tickets: undefined,
  tickets_error: undefined,
  verify_success: false,
  verify_error: undefined,
});

export const loaded = (data) => (
  { type: USER.SUCCESS, data }
);

export const loadedData = (data) => (
  { type: USER.DATA_SUCCESS, data }
);

export const loadedPolicies = (data) => (
  { type: USER.POLICIES_SUCCESS, data }
);

const loading = () => (
  { type: USER.LOADING }
);

const saveDone = (data) => (
  { type: USER.SAVE_SUCCESS, data }
);

const loadingError = (error) => (
  { type: USER.ERROR, error }
);

const setTickets = (data) => (
  { type: USER.TICKETS_SET, data }
);

const ticketsError = (error) => (
  { type: USER.TICKETS_ERROR, error }
);

const verifyDone = (data) => (
  { type: USER.VERIFY_SUCCESS, data }
);

const verifyError = (err) => (
  { type: USER.VERIFY_ERROR, err }
);

const { condition: onlyOnce } = createOnlyOnceCondition();
const defaults = {
  onLoading: () => loading(),
  onSuccess: ({ data }) => loaded(data),
  onFailure: (error) => loadingError(error),
};

export const getUserData = () => requestAndDispatch({
  url: '1/identity/me/data',
  onSuccess: ({ data }) => loadedData(data),
  condition: onlyOnce,
}, defaults);

export const getPolicies = (force) => requestAndDispatch({
  url: '1/identity/me/policies',
  onSuccess: ({ data }) => loadedPolicies(data),
  condition: !force && onlyOnce,
}, defaults);

export const saveUser = (user) => postAndDispatch({
  url: '1/identity/me',
  data: user,
  onSuccess: ({ data }) => saveDone(data),
}, defaults);

export const saveUserData = (d) => postAndDispatch({
  url: '1/identity/me/data',
  data: d,
  onSuccess: ({ data }) => loadedData(data),
}, defaults);

export function createAccount(user) {
  return async (dispatch) => {
    dispatch(loading());
    const csrfToken = await getCsrfToken();
    dhid.request(`${dhid.getBaseUrl()}/register`, {
      method: 'post',
      data: {
        ...user,
        csrfToken,
      },
      withCredentials: true,
    })
      .then(() => {
        dispatch(attemptLogin(user));
        return true;
      })
      .catch((error) => {
        dispatch(loadingError(error));
        return false;
      });
  };
}

export const verifyEmail = (token) => postAndDispatch({
  url: '1/identity/me/verify',
  data: { verify_email_token: token },
  onSuccess: ({ data }) => verifyDone(data),
  onFailure: (err) => verifyError(err),
}, defaults);

export const sendVerifyEmail = () => postAndDispatch({
  url: '1/identity/me/verify/email',
  data: { },
  onSuccess: () => saveDone(),
}, defaults);

export const getTickets = () => requestAndDispatch({
  url: '1/content/tickets/me',
  onSuccess: ({ data }) => setTickets(data),
  onFailure: (error) => ticketsError(error),
  condition: onlyOnce, // TODO: ageCondition
}, defaults);

export const fetchTicket = (order, email, id = undefined) => postAndDispatch({
  url: '1/content/tickets/fetch',
  data: { order, email, id },
  onSuccess: ({ data }) => setTickets(data.tickets),
  onFailure: (error) => {
    if (error && error.response && error.response.data && error.response.data.status) {
      return ticketsError(error.response.data.error);
    }

    return ticketsError(error.message);
  },
}, defaults);

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USER.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('data', action.data);
    }
    case USER.SAVE_SUCCESS: {
      if (action.data) {
        return state
          .set('loading', false)
          .set('data', {
            ...state.get('data'),
            ...action.data,
          })
          .set('error', undefined);
      }
      return state
        .set('loading', false)
        .set('error', undefined);
    }
    case USER.DATA_SUCCESS: {
      return state
        .set('user_data', action.data.data);
    }
    case USER.POLICIES_SUCCESS: {
      return state
        .set('policies', action.data.policies)
        .set('missing_fields', action.data.missing)
        .set('required_fields', action.data.required);
    }
    case USER.LOADING: {
      return state
        .set('error', undefined)
        .set('tickets_error', undefined)
        .set('loading', true);
    }
    case USER.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case USER.TICKETS_SET: {
      return state
        .set('loading', false)
        .set('tickets_error', undefined)
        .set('tickets', action.data);
    }
    case USER.TICKETS_ERROR: {
      return state
        .set('loading', false)
        .set('tickets_error', action.error);
    }
    case USER.VERIFY_SUCCESS: {
      return state
        .set('error', undefined)
        .set('data', action.data)
        .set('verify_error', undefined)
        .set('verify_success', true);
    }
    case USER.VERIFY_ERROR: {
      return state
        .set('verify_error', action.err)
        .set('verify_success', false);
    }
    case AUTH.LOGOUT:
      return state
        .set('loading', false)
        .set('error', undefined)
        .set('data', undefined);
    default: {
      return state;
    }
  }
}
