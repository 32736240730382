// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-Account-components-Button-button__button--7Pn7a{border:0;background:#ff6600;background:linear-gradient(45deg, #cc5200, #f60);color:#fff;text-transform:uppercase;font-size:0.8em;font-weight:600;padding:18px;cursor:pointer;margin-top:24px;outline:0}.src-routes-Account-components-Button-button__button--7Pn7a:hover{background:#e65c00;background:linear-gradient(45deg, #b34700, #e65c00)}.src-routes-Account-components-Button-button__button--7Pn7a:disabled{color:#555;background:#333;cursor:not-allowed}\n", ""]);
// Exports
exports.locals = {
	"button": "src-routes-Account-components-Button-button__button--7Pn7a"
};
module.exports = exports;
