import { history as historyPropTypes } from 'history-prop-types';
import PropTypes from 'prop-types';
import React from 'react';
import btoa from 'btoa';

import withUser from './withUser';

function getDisplayName(WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

function requireUser(WrappedComponent) {
  const propTypes = {
    history: PropTypes.shape(historyPropTypes),
    user: PropTypes.shape(),
  };

  class RequireUser extends React.Component {
    componentDidMount() {
      this.UNSAFE_componentWillReceiveProps(this.props);
    }

    UNSAFE_componentWillReceiveProps(newProps) {
      const { history } = this.props;
      const { user } = newProps;

      if (!user) {
        const hash = btoa(`${history.location.pathname}${history.location.search}`);
        history.push(`/login/${hash}`);
      }
    }

    render() {
      // Remove props that should not be forwared
      const { user } = this.props;
      const { ...passThroughProps } = this.props;

      return user ? (
        <WrappedComponent
          {...passThroughProps}
        />
      ) : null;
    }
  }

  RequireUser.displayName = `requireUser(${getDisplayName(WrappedComponent)})`;
  RequireUser.propTypes = propTypes;

  return withUser(RequireUser);
}

export default requireUser;
