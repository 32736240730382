import { combineReducers } from 'redux-immutable';

import achievements from './achievements';
import app from './app';
import auth from './user/auth';
import connections from './user/connections';
import crewApplications from './crew/applications';
import crewCalendar from './crew/calendar';
import crewCheckins from './crew/checkins';
import crewDocuments from './crew/documents';
import crewEval from './crew/evaluations';
import crewFeed from './crew/feed';
import crewNotifications from './crew/notifications';
import crewTeams from './crew/team';
import crewUsers from './crew/users';
import data from './user/data';
import event from './event';
import events from './events';
import leaderboards from './leaderboards';
import maps from './maps';
import mfa from './user/mfa';
import mydata from './user/mydata';
import myorders from './myorders';
import notifications from './notifications';
import question from './question';
import seatmap from './seatmap';
import streams from './streams';
import user from './user/user';
import users from './users';

const rootReducer = combineReducers({
  achievements,
  app,
  auth,
  connections,
  crewApplications,
  crewCalendar,
  crewCheckins,
  crewDocuments,
  crewEval,
  crewFeed,
  crewNotifications,
  crewTeams,
  crewUsers,
  data,
  event,
  events,
  leaderboards,
  maps,
  mfa,
  mydata,
  myorders,
  notifications,
  question,
  seatmap,
  streams,
  user,
  users,
});

export default rootReducer;
