// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-routes-App-components-Content-content__content--2MxTF{display:flex;flex-direction:column;overflow-y:auto;background:#050505;height:100%;position:relative;font-size:12px;-webkit-overflow-scrolling:touch}.src-routes-App-components-Content-content__ptrContainer--2tVnC{background:#050505;height:100%;position:relative}.src-routes-App-components-Content-content__ptrContainer--2tVnC .src-routes-App-components-Content-content__ptrStatus--2ie7-{overflow:hidden;display:flex;justify-content:center;align-items:center;color:#818384;font-size:0.9em}.src-routes-App-components-Content-content__ptrContainer--2tVnC .src-routes-App-components-Content-content__ptrStatus--2ie7- i{font-size:1.5em}.src-routes-App-components-Content-content__ptrContainer--2tVnC .src-routes-App-components-Content-content__ptr---TSFq{position:absolute;top:0;bottom:0;left:0;right:0}.src-routes-App-components-Content-content__transparent--2RTEK{background:transparent}.src-routes-App-components-Content-content__transparent--2RTEK .src-routes-App-components-Content-content__ptrStatus--2ie7-{display:none}.src-routes-App-components-Content-content__rotate--3_7EZ{animation-name:src-routes-App-components-Content-content__spin--3YFEg;animation-duration:2s;animation-iteration-count:infinite;animation-timing-function:linear;animation-fill-mode:forwards}@keyframes src-routes-App-components-Content-content__spin--3YFEg{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}\n", ""]);
// Exports
exports.locals = {
	"content": "src-routes-App-components-Content-content__content--2MxTF",
	"ptrContainer": "src-routes-App-components-Content-content__ptrContainer--2tVnC",
	"ptrStatus": "src-routes-App-components-Content-content__ptrStatus--2ie7-",
	"ptr": "src-routes-App-components-Content-content__ptr---TSFq",
	"transparent": "src-routes-App-components-Content-content__transparent--2RTEK",
	"rotate": "src-routes-App-components-Content-content__rotate--3_7EZ",
	"spin": "src-routes-App-components-Content-content__spin--3YFEg"
};
module.exports = exports;
