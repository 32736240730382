import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from '../user/auth';
import { ERROR, LOADING, SUCCESS } from '../stateConstants';
import { createOnlyOnceCondition } from '../conditions';
import { deleteAndDispatch, postAndDispatch, putAndDispatch } from '../helpers';

export const CREW_TEAM = defineAction(
  'CREW_TEAM',
  [LOADING, SUCCESS, ERROR, 'ADDED'],
  'crew_team',
);

const defaultState = Map({
  loading: false,
  requests: 0,
  list: Map(),
  memberships: Map(),
  rootsByEvent: undefined,
  error: undefined,
});

// Actions
const defaults = {
  onLoading: () => ({ type: CREW_TEAM.LOADING }),
  onSuccess: ({ data }) => ({ type: CREW_TEAM.SUCCESS, data }),
  onFailure: (error) => ({ type: CREW_TEAM.ERROR, error }),
};

const { condition: onlyOnce, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();

export const getTeamsForEvents = (events, options) => postAndDispatch(
  {
    url: '1/identity/groups/search',
    data: onlyOnce({
      attributes: {
        crew_team: true,
        event: events,
      },
      ...options,
    }),
  },
  defaults,
);

export const getTeam = (id, options) => postAndDispatch(
  {
    url: '1/identity/groups/search',
    data: onlyOnce({
      id,
      attributes: {
        crew_team: true,
      },
      ...options,
    }),
  },
  defaults,
);

export const getTeams = (ids, options) => postAndDispatch(
  {
    url: '1/identity/groups/search',
    data: onlyOnce({
      id: ids,
      with: {
        ancestors: true,
      },
      ...options,
    }),
  },
  defaults,
);

export const addTeam = (name, attributes, event_shortcode) => postAndDispatch(
  {
    url: '1/identity/groups',
    data: {
      name,
      attributes,
      event_shortcode,
    },
    onSuccess: ({ data }) => ({ type: CREW_TEAM.ADDED, data }),
  },
  defaults,
);

// Members
export const addMember = (team_id, user_id, group_id) => postAndDispatch(
  {
    url: `1/identity/groups/${team_id}/members`,
    data: {
      user_id,
      group_id,
    },
  },
  defaults,
);

export const updateMember = (team_id, membership_id, data) => putAndDispatch(
  {
    url: `1/identity/groups/${team_id}/members/${membership_id}`,
    data,
  },
  defaults,
);

export const moveMember = (team_id, membership_id, from, to) => postAndDispatch(
  {
    url: `1/identity/groups/${team_id}/members/${membership_id}/move`,
    data: {
      from,
      to,
    },
    onSuccess: ({ data }) => ({
      type: CREW_TEAM.SUCCESS,
      data,
      replace: true,
    }),
  },
  defaults,
);

export const removeMember = (team_id, membership_id) => deleteAndDispatch(
  {
    url: `1/identity/groups/${team_id}/members/${membership_id}`,
    onSuccess: ({ data }) => ({
      type: CREW_TEAM.SUCCESS,
      data,
      replace: true,
    }),
  },
  defaults,
);

// Team sub-groups
export const createGroup = (team, data) => postAndDispatch(
  {
    url: `1/identity/groups/${team}/group`,
    data,
  },
  defaults,
);

export const updateGroup = (team_id, group_id, data) => putAndDispatch(
  {
    url: `1/identity/groups/${team_id}/group/${group_id}`,
    data,
  },
  defaults,
);

export const moveGroup = (team, group_id, from, to) => postAndDispatch(
  {
    url: `1/identity/groups/${team}/group/move`,
    data: {
      group_id,
      from,
      to,
    },
    onSuccess: ({ data }) => ({
      type: CREW_TEAM.SUCCESS,
      data,
      replace: true,
    }),
  },
  defaults,
);

export const removeGroup = (team_id, group_id) => deleteAndDispatch(
  {
    url: `1/identity/groups/${team_id}/group/${group_id}`,
    onSuccess: ({ data }) => ({
      type: CREW_TEAM.SUCCESS,
      data,
      replace: true,
    }),
  },
  defaults,
);

// Reducer

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case CREW_TEAM.LOADING: {
      return state
        .set('requests', state.get('requests') + 1)
        .set('loading', true)
        .set('error', undefined);
    }
    case CREW_TEAM.ERROR: {
      return state
        .set('requests', state.get('requests') - 1)
        .set('loading', state.get('requests') > 1)
        .set('error', serializeError(action.error));
    }
    case CREW_TEAM.SUCCESS: {
      const map = {}; // make them accessible by id on this map
      let notUpdated = [];
      if (action.data) {
        action.data.forEach((item) => {
          const { children, descendants, ...values } = item;
          map[item.id] = values;
          if (children) {
            notUpdated = state
              .get('list')
              .filter((group) => group.get('parent_id') === item.id)
              .map((group) => group.get('id'))
              .toArray();

            children.forEach((child) => {
              map[child.id] = child;
              notUpdated = notUpdated.filter((id) => id !== child.id);
            });
          }
          if (descendants) {
            descendants.forEach((child) => {
              map[child.id] = child;
            });
          }
        });
      }

      let newState = state
        .set('requests', state.get('requests') - 1)
        .set('loading', state.get('requests') > 1)
        .set('error', undefined)
        .set('list', state.get('list').mergeDeep(map));

      if (action.replace) {
        Object.keys(map).forEach((id) => {
          newState = newState.setIn(
            ['list', id, 'memberships'],
            fromJS(map[id].memberships),
          );
        });
        if (notUpdated) {
          notUpdated.forEach((id) => {
            newState = newState.deleteIn(['list', id]);
          });
        }
      }

      // Update the relations map
      newState = newState.set(
        'memberships',
        fromJS(newState.get('list').reduce(
          // Loop thru all groups
          (acc, group) => (group.get('memberships')
            ? group.get('memberships').reduce((acc2, membership) => {
              // Loop thru all group members
              const id = membership.getIn(['member', 'id']);
              if (id && (!acc2[id] || acc2[id][group.get('id')] === undefined)) {
                // Add the relation to the map (the group id is key and value is the crew_team)
                  acc2[id] = { ...(acc2[id] || {}), [group.get('id')]: '' }; // eslint-disable-line

                // Lookup the main group (has attribute crew_team)
                let parent = group;
                while (parent && !parent.getIn(['attributes', 'crew_team'])) {
                  parent = newState.getIn(['list', parent.get('parent_id')]);
                }

                // If we found it, save it as value
                if (parent && parent.getIn(['attributes', 'crew_team'])) {
                    acc2[id][group.get('id')] = parent.get('id'); // eslint-disable-line
                }
              }

              return acc2;
            }, acc)
            : acc),
          {},
        )),
      );

      return newState;
    }
    case CREW_TEAM.ADDED: {
      return state
        .set('loading', false)
        .set('error', undefined)
        .set(
          'list',
          state.get('list').mergeDeep({
            [action.data.id]: {
              ...action.data,
              total_members: 0,
            },
          }),
        );
    }
    // case GET.SUCCESS: {

    // const updatedState = state
    // .set('loading', false)
    // .set('list', state.get('list').mergeDeep(map));

    // return updatedState;
    // //return updatedState
    // //.set('data', flatToHierarchy(updatedState.get('list')));
    // }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      return defaultState;
    default: {
      return state;
    }
  }
}
