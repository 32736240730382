import PropTypes from 'prop-types';
import React from 'react';

import styles from './stream.scss';

const propTypes = {
  provider: PropTypes.string,
  provider_data: PropTypes.string,
};

const embedTwitch = (channel) => (
  // For debug purposes:
  // <div className={classNames(styles.iframeContainer)}>
  //   This is twitchstream from {channel}
  // </div>
  <iframe
    title={`Stream from ${channel}`}
    src={`https://player.twitch.tv/?channel=${channel}`}
    frameBorder="0"
    allowFullScreen
    autoPlay={false}
    webkitplaysinline="true"
  />
);

const embedHtml = (html) => (
  <div
    className={styles.iframeContainer}
    dangerouslySetInnerHTML={{ __html: html }} // eslint-disable-line react/no-danger
  />
);

const embedLink = (url) => (
  <div>
    Watch the external broadcast
    <a href={url}>here</a>
  </div>
);

const mapProviderToEmbed = {
  twitch: embedTwitch,
  html: embedHtml,
  link: embedLink,
};

const Stream = ({ provider, provider_data }) => (
  <div className={styles.stream}>
    { provider && provider_data
      ? mapProviderToEmbed[provider](provider_data)
      : <div>No broadcast selected</div>}
  </div>
);

Stream.propTypes = propTypes;

export default Stream;
