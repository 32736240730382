import { Map, fromJS } from 'immutable';
import { defineAction } from 'redux-define';
import { serializeError } from 'serialize-error';

import { AUTH } from './user/auth';
import { ERROR, LOADING, SUCCESS } from './stateConstants';
import { createOnlyOnceCondition } from './conditions';
import { requestAndDispatch } from './helpers';

const USERS = defineAction(
  'users',
  [LOADING, SUCCESS, ERROR],
  'users',
);

const defaultState = Map({
  list: Map(),
  loading: false,
  error: false,
});

const loaded = (data) => (
  { type: USERS.SUCCESS, data }
);

const loading = () => (
  { type: USERS.LOADING }
);

const loadingError = (error) => (
  { type: USERS.ERROR, error }
);

const defaults = {
  onLoading: () => loading(),
  onFailure: (error) => loadingError(error),
  onSuccess: ({ data }) => loaded(data),
};

const { condition: onlyOnce, clear: clearOnlyOnceCondition } = createOnlyOnceCondition();
export const getPublicUser = (id) => requestAndDispatch({
  url: `1/identity/users/${id}/public`,
  condition: onlyOnce,
}, defaults);


export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case USERS.SUCCESS: {
      return state
        .set('loading', false)
        .set('error', false)
        .setIn(['list', action.data.id], fromJS(action.data));
    }
    case USERS.LOADING: {
      return state
        .set('error', false) // Clear the previous error
        .set('loading', true);
    }
    case USERS.ERROR: {
      return state
        .set('loading', false)
        .set('error', serializeError(action.error));
    }
    case AUTH.LOGOUT:
      clearOnlyOnceCondition();
      return state
        .set('loading', false)
        .set('error', false)
        .set('list', Map());
    default: {
      return state;
    }
  }
}
