import PropTypes from 'prop-types';
import React from 'react';

import styles from './button.scss';

const propTypes = {
  children: PropTypes.node,
};

const Button = (props) => (
  <button
    type="button"
    {...props}
    className={styles.button}
  >
    {props.children}
  </button>
);

Button.propTypes = propTypes;

export default Button;
